import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSVGIcon from 'vue-svgicon';
import VModal from 'vue-js-modal';
import {BootstrapVue} from 'bootstrap-vue';
import Clipboard from 'v-clipboard';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

Vue.config.productionTip = false;

Vue.use(VueSVGIcon);
Vue.use(VModal, {dynamic: true});
Vue.use(BootstrapVue);
Vue.use(Clipboard);
Vue.use(VueTimepicker);

window.app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
