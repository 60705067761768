import Popup from "@/lib/Popup";
import EditAuthorModal from '@/components/modals/EditAuthorModal';

export const openEditAuthorModal = author => {
    const popup = new Popup({
        component: EditAuthorModal,
        props: {author},
        params: {
            transition: 'scale',
            name: 'edit-author-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openEditAuthorModal};
