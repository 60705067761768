import {UPDATE, update} from '../utils';

import api from '@/api';

const state = {
    list: []
};

const getters = {};

const actions = {
    update,
    
    async getPermissions({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/permissions').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
};

const mutations = {
    UPDATE,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
