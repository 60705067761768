import Vue from 'vue';

import {UPDATE, update} from '../utils';

import api from '@/api';

const state = {
    config: []
};

const getters = {};

const actions = {
    update,
    
    async getDailyBonusConfig({state, commit}) {
        return new Promise(resolve => {
            if (state.config.length) {
                resolve(state.config);
                return;
            }
            
            api.fetch('/daily_bonuses').then(config => {
                commit('UPDATE', {config});
                resolve(config);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async setDailyBonusEnabled({commit}, is_enable) {
        return new Promise(resolve => {
            api.put('/daily_bonuses', {is_enable}).then(isEnabled => {
                commit('SET_BONUS_ENABLED', isEnabled);
                resolve(isEnabled);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async saveDailyBonusConfig({commit}, config) {
        return new Promise(resolve => {
            api.post('/daily_bonuses', {bonuses: config}).then(config => {
                commit('UPDATE', {config});
                resolve(config);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    
};

const mutations = {
    UPDATE,
    
    SET_BONUS_ENABLED(state, isEnabled) {
        Vue.set(state.config, 'is_enable', isEnabled);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
