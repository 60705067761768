<template>
    <div class="page sale-action-page">
        <div class="page__title">
            <nav-back/>
            Настройки акций (в разработке)
            <ui-button color="blue" class="page__title-button" @clicked="onSaveClick">
                Сохранить
            </ui-button>
        </div>

        <div class="sale-action-page__settings">
            <label class="my-0 text-left">Заголовок</label>
            <input type="text" class="form-control" v-model="title"/>

            <label class="my-0 text-left">Текст</label>
            <textarea class="form-control" v-model="description"/>

            <label class="my-0 text-left">Картинка</label>
            <img v-if="imageUrl" :src="imageUrl" title="Заменить" @click="imageUrl = ''" style="max-width: 220px;"/>
            <input v-else type="file" accept="image/png" @change="loadImage" ref="imageUploadButton"/>

            <label class="my-0 text-left">Длительность таймера (ч)</label>
            <input type="number" class="form-control" v-model.number="timerDuration"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">Повторный показ</label>
            <b-dropdown :text="repeatText">
                <b-dropdown-item v-for="(option, i) of yesNoOptions" :key="i" @click="onRepeatOptionSelect"
                                 :data-value="option.id">
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
            <template v-if="repeatOption == 0">
                <label class="my-0 text-left">Интервал повторения (ч)</label>
                <input type="number" class="form-control" v-model.number="repeatIntervalHours"
                       min=0 oninput="validity.valid||(value=0)"
                />

                <label class="my-0 text-left">Кол-во повторений</label>
                <input type="number" class="form-control" v-model.number="repeatTimesCount"
                       min=0 oninput="validity.valid||(value=0)"
                />
            </template>

            <label class="my-0 text-left">Кол-во сессий</label>
            <input type="number" class="form-control" v-model.number="sessionsCount"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">Кол-во прочтенных глав</label>
            <input type="number" class="form-control" v-model.number="chaptersCount"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">Платящий</label>
            <b-dropdown :text="payerText">
                <b-dropdown-item v-for="(option, i) of noMatterYesNoOptions" :key="i" @click="onPayerOptionSelect"
                                 :data-value="option.id">
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>

            <label class="my-0 text-left">Пол</label>
            <b-dropdown :text="genderText">
                <b-dropdown-item v-for="(option, i) of genderOptions" :key="i" @click="onGenderOptionSelect"
                                 :data-value="option.id">
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import {uploadImage} from '@/utils';

export default {
    data: () => ({
        title: '',
        description: '',
        imageUrl: '',
        timerDuration: 0,
        sessionsCount: 0,
        chaptersCount: 0,

        yesNoOptions: [
            {id: 0, name: 'Да'},
            {id: 1, name: 'Нет'},
        ],
        noMatterYesNoOptions: [
            {id: 0, name: 'Не важно'},
            {id: 1, name: 'Да'},
            {id: 2, name: 'Нет'},
        ],
        genderOptions: [
            {id: 0, name: 'Не важно'},
            {id: 1, name: 'Мужской'},
            {id: 2, name: 'Женский'},
        ],

        payerOption: 0,
        payerText: '',

        genderOption: 0,
        genderText: '',

        repeatOption: 0,
        repeatText: '',
        repeatIntervalHours: 0,
        repeatTimesCount: 0
    }),
    components: {
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
    },
    created() {
        this.payerText = this.noMatterYesNoOptions[this.payerOption].name;
        this.genderText = this.genderOptions[this.genderOption].name;
        this.repeatText = this.yesNoOptions[this.repeatOption].name;
    },
    methods: {
        loadImage(event) {
            uploadImage(event, result => this.imageUrl = result);
        },
        onPayerOptionSelect(event) {
            this.payerOption = parseInt(event.target.dataset.value, 10);
            this.payerText = this.noMatterYesNoOptions[this.payerOption].name;
        },
        onGenderOptionSelect(event) {
            this.genderOption = parseInt(event.target.dataset.value, 10);
            this.genderText = this.genderOptions[this.genderOption].name;
        },
        onRepeatOptionSelect(event) {
            this.repeatOption = parseInt(event.target.dataset.value, 10);
            this.repeatText = this.yesNoOptions[this.repeatOption].name;

            if (this.repeatOption == 1) {
                this.repeatIntervalHours = 0;
                this.repeatTimesCount = 0;
            }
        },
        onSaveClick() {
            alert('onSaveClick');
        }
    }
};
</script>

<style lang="scss">
.sale-action-page {
    &__settings {
        margin-top: 12px;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-row-gap: 12px;
        grid-column-gap: 8px;
        align-items: center;
    }
}
</style>
