<template>
    <div class="home-page page">
        <h1>Загрузка</h1>
    </div>
</template>

<script>
export default {
    created() {
        this.$router.push(this.$store.state.currentUser.token ? 'stories' : 'login');
    }
}
</script>
