import api from '@/api';
import {UPDATE, update} from '../utils';
import Vue from 'vue';

const state = {
    /**
     * [...{id: number, title: string, description: string, currency: id, price: number}]
     */
    list: {}
};

const getters = {
    multiProductsList: (state) => {
        const result = [];
        Object.values(state.list).forEach(data => {
            if (data.rewards.length !== 1) result.push(data);
        });
        return result;
    },
    singleProductsList: (state) => {
        const result = {};
        Object.values(state.list).forEach(data => {
            if (data.rewards.length === 1) {
                if (!result[data.rewards[0].id]) result[data.rewards[0].id] = [];
                result[data.rewards[0].id].push(data);
            }
        });
        return result;
    }
};

const actions = {
    update,
    
    async getProducts({commit}) {
        return new Promise(resolve => {
            if (Object.keys(state.list).length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/products')
                .then(response => {
                    commit('SET_CONFIG', response.products);
                    resolve(response.products);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async addProduct({commit}, productData) {
        return new Promise(resolve => {
            api.post('/products', productData)
                .then(productData => {
                    commit('UPDATE_PRODUCT', productData);
                    resolve(productData);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async updateProduct({commit}, productData) {
        return new Promise(resolve => {
            api.put(`/products/${productData.id}`, productData)
                .then(productData => {
                    commit('UPDATE_PRODUCT', productData);
                    resolve(productData);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async deleteProduct({commit}, productId) {
        return new Promise(resolve => {
            api.delete(`/products/${productId}`)
                .then(_ => {
                    commit('DELETE_PRODUCT', productId);
                    resolve({success: 1});
                })
                .catch(error => resolve({error}));
        });
    },
    
    async saveProductsOrder({commit}, products) {
        return new Promise(resolve => {
            api.put('/products', {products})
                .then(_ => {
                    commit('SET_CONFIG', products);
                    resolve({success: 1});
                })
                .catch(error => resolve({error}));
        });
    }
};

const mutations = {
    UPDATE,
    
    SET_CONFIG(state, list) {
        const config = {};
        list.forEach(data => config[data.id] = data);
        state.list = config;
    },
    
    UPDATE_PRODUCT(state, newConfig) {
        Vue.set(state.list, newConfig.id, newConfig);
    },
    
    DELETE_PRODUCT(state, productId) {
        Vue.delete(state.list, productId);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
