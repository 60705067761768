const getRandomId = () => 1000 + Math.floor(Math.random() * 1000);

class ApiMock {
    constructor(params = {}) {
        this.rejectMode = params.rejectMode;
    }
    
    fetch(url, params) {
        return new Promise((resolve, reject) => {
            if (this.rejectMode) {
                reject({error: 1});
            } else {
                switch (url) {
                    case '/config':
                        resolve({
                            chapter_reward_currency_id: 1,
                            chapter_reward_currency_value: 1,
                            start_story: 1,
                            start_story_adult: 2,
                            story_reward_currency_id: 2,
                            story_reward_currency_value: 10,
                            customization_currency_id: 2,
                            customization_currency_value: 100,
                            story_unlock_currency_id: 1,
                            story_unlock_currency_value: 50,
                        });
                        break;
                    case '/exchanges':
                        resolve([
                            {
                                id: 1,
                                source_currency_id: 1,
                                source_currency_value: 5,
                                destination_currency_id: 2,
                                destination_currency_value: 1
                            }
                        ]);
                        break;
                    default:
                        resolve({status: 'ok'});
                }
            }
        });
    }
    
    post(url, params) {
        return new Promise((resolve, reject) => {
            if (this.rejectMode) {
                reject({error: 1});
            } else {
                if (url.indexOf('/chapters') > -1) {
                    params.id = getRandomId();
                    resolve(params);
                    return;
                }
                
                switch (url) {
                    case '/user/auth':
                        resolve({
                            id: 1,
                            token: 'asdalshdfds',
                            permissions: [1, 2, 3, 4],
                            email: 'test@ya.ru'
                        });
                        break;
                    case '/users':
                        resolve({
                            id: getRandomId(),
                            email: params.email,
                            permissions: params.permissions
                        });
                        break;
                    case '/currencies':
                    case '/categories':
                    case '/genres':
                    case '/authors':
                        resolve({
                            id: getRandomId(),
                            name: params.name
                        });
                        break;
                    case '/skills':
                    case '/stories':
                    case '/exchanges':
                    case url.indexOf('/prices') > -1:
                        params.id = getRandomId();
                        resolve(params);
                        break;
                    default:
                        resolve({status: 'ok'});
                }
            }
        });
    }
    
    patch(url, params) {
        return new Promise((resolve, reject) => {
            if (this.rejectMode) {
                reject({error: 1});
            } else {
                switch (url) {
                    default:
                        resolve(params);
                }
            }
        });
    }
    
    put(url, params) {
        return new Promise((resolve, reject) => {
            if (this.rejectMode) {
                reject({error: 1});
            } else {
                const chaptersRegExp = new RegExp('\\/stories\\/(\\d+)\\/chapters\\/(\\d+)');
                
                if (chaptersRegExp.test(url)) {
                    resolve(params);
                    return;
                }
                
                switch (url) {
                    default:
                        resolve(params);
                }
            }
        });
    }
    
    delete(url, params) {
        return new Promise((resolve, reject) => {
            if (this.rejectMode) {
                reject({error: 1});
            } else {
                if (url.indexOf('/chapters') > -1) {
                    const chapterId = parseInt(url.split('/chapters/')[1], 10);
                    
                    resolve({
                        chapterId,
                        storyId: params.story_id
                    });
                    return;
                }
                
                if (url.indexOf('/stories') > -1) {
                    const storyId = parseInt(url.split('/stories/')[1], 10);
                    
                    resolve(storyId);
                    return;
                }
                
                switch (url) {
                    default:
                        resolve(params);
                }
            }
        });
    }
}

const apiMock = new ApiMock();

export default apiMock;
