<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onAddClick">
        <div class="mb-3 modal-popup__title">
            Добавление ответа

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <label class="modal-popup__label">Описание</label>
        <textarea class="form-control" placeholder="Введите описание" v-model="description" ref="input"/>
        <label class="modal-popup__label">Валюта</label>
        <b-form-select class="form-control" v-model="selectedCurrencyValue" :options="currenciesOptions"/>
        <label class="modal-popup__label">Цена</label>
        <input type="number" v-model.number="currencyPriceValue" class="form-control mt-2"
               min=0 oninput="validity.valid||(value=0)"
        />
        <ui-button class="mt-3 mb-2" @clicked="onAddClick">Добавить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        description: '',
        alert: '',
        canReset: false,

        currenciesOptions: [],
        selectedCurrencyValue: 0,
        currencyPriceValue: 0
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    async created() {
        await this.getCurrencies();
        this.fillSelects();
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('prices', ['addPrice']),
        fillSelects() {
            this.currenciesOptions = this.$store.state.currencies.list
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });
            this.selectedCurrencyValue = this.currenciesOptions[0].value;
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onAddClick() {
            const res = await this.addPrice({
                description: this.description,
                currency_id: parseInt(this.selectedCurrencyValue, 10),
                currency_value: parseInt(this.currencyPriceValue, 10)
            });

            if (!res.error) {
                bus.$emit('show-notification-message', 'Добавлено');
                this.close();
            } else {
                alert(`Ошибка: ${res.error.toString()}`);
            }
        },
        close() {
            this.$modal.hide('add-price-modal');
        }
    }
};
</script>
