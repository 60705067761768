<template>
    <div class="packs-list mb-4">
        <div class="packs-list__title mb-2">{{ name }}</div>
        <table v-if="packsList.length" class="table table-striped packs-list__table">
            <thead>
            <tr>
                <th scope="col">Изображение</th>
                <th scope="col">ID Google</th>
                <th scope="col">ID Apple</th>
                <th scope="col">Кол-во</th>
                <th scope="col">Бонус покупки %</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tr v-for="(pack, i) of packsList" :key="i">
                <td>
                    <img v-if="pack.image_url" :src="pack.image_url" title="Заменить"
                         @click="pack.image_url = ''"/>
                    <input v-else type="file" accept="image/png" @change="loadImage($event, pack.id)"
                           ref="imageUploadButton" class="d-flex"/>
                </td>
                <td><input type="text" v-model="pack.google_id" class="form-control"/></td>
                <td><input type="text" v-model="pack.apple_id" class="form-control"/></td>
                <td>
                    <input type="number" v-model.number="pack.rewards[0].value" class="form-control"
                           min=0 oninput="validity.valid||(value=0)"/>
                </td>
                <td>
                    <input type="number" :value="firstBonus[pack.id]" class="form-control"
                           min=0 oninput="validity.valid||(value=0)"
                           @change="onFirstBonusChange(pack.id, $event)"/>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Сохранить"
                         @click="onSavePackClick(pack)"
                    >
                        <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path
                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-trash ms-3 ml-2" viewBox="0 0 16 16" v-b-tooltip.hover title="Удалить"
                         @click="onDeletePackClick(pack.id)"
                    >
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </td>
            </tr>
            <tbody>
            </tbody>
        </table>
        <ui-button class="packs-list__add-button" @clicked="onAddPackClick">Добавить товар</ui-button>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';
import {toDataURL, uploadImage} from '@/utils';

export default {
    props: {
        id: Number,
        name: String,
        packs: Array
    },
    data: () => ({
        packsList: [],
        firstBonus: {}
    }),
    components: {
        UiButton: () => import('../../components/ui/UiButton')
    },
    created() {
        this.packsList = this.packs || [];
        this.packsList.forEach(pack => {
            pack.image_url.length && toDataURL(pack.image_url, result => pack.image_url = result);
            this.firstBonus[pack.id] = Math.max(pack.first_bonus, 0);
        });
    },
    computed: {
        nextOrder() {
            let nextOrder = 0;
            this.packsList.forEach(pack => {
                if (pack.order > nextOrder) nextOrder = pack.order + 1;
            });
            return nextOrder;
        }
    },
    methods: {
        ...mapActions('products', ['addProduct', 'updateProduct', 'deleteProduct']),
        onAddPackClick() {
            this.packsList.push({
                id: 0,
                google_id: '',
                apple_id: '',
                image_url: '',
                rewards: [{id: this.id, value: 0}],
                order: this.nextOrder
            });
        },
        async onSavePackClick(pack) {
            const data = {
                google_id: pack.google_id,
                apple_id: pack.apple_id,
                first_bonus: this.firstBonus[pack.id],
                rewards: [{
                    id: parseInt(this.id, 10),
                    value: parseInt(pack.rewards[0].value, 10)
                }]
            };

            if (pack.image_url.includes(';base64,'))
                data.image = pack.image_url.replace(/^data:.+;base64,/, '');
            else if (!pack.image_url.includes('https://')) {
                alert('Необходимо загрузить иконку продукта');
                return;
            }

            if (pack.id) data.id = pack.id;

            const res = pack.id ? await this.updateProduct(data) : await this.addProduct(data);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Обновлено');

                if (!pack.id) {
                    this.packsList.push(res);
                    this.packsList = this.packsList.filter(pack => pack.id !== 0);
                }
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        async onDeletePackClick(productId) {
            if (!confirm('Подтвердите удаление'))
                return;
            const res = await this.deleteProduct(productId);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Удалено');
                this.packsList = this.packsList.filter(pack => pack.id !== productId);
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        loadImage(event, productId) {
            uploadImage(event, result => {
                const pack = this.packsList.find(product => product.id === productId);
                pack.image_url = result;
            });
        },
        onFirstBonusChange(packId, event) {
            let value = parseInt(event.target.value, 10);
            if (isNaN(value)) value = 0;
            this.firstBonus[packId] = value;
        },
    }
};
</script>

<style lang="scss">
.packs-list {
    &__title {
        font-weight: bold;
        font-size: 24px;
        text-align: left;
    }

    &__table {
        td {
            vertical-align: middle;
        }
    }

    &__add-button {
        float: left;
    }

    img {
        cursor: pointer;
        max-width: 160px;
    }
}
</style>
