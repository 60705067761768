import Popup from "@/lib/Popup";
import EditUserModal from '@/components/modals/EditUserModal';

export const openEditUserModal = user => {
    const popup = new Popup({
        component: EditUserModal,
        props: {user},
        params: {
            transition: 'scale',
            name: 'edit-user-modal',
            width: '100%',
            height: '100%',
            classes: 'edit-user-modal__overlay'
        }
    });
    
    popup.show();
};

export default {openEditUserModal};
