<template>
    <div class="page season-first-purchase-page">
        <div class="page__title">
            <nav-back/>
            Сезонные первые покупки (в разработке)
            <ui-button color="blue" class="page__title-button" @clicked="onAddSeasonClick">Добавить</ui-button>
        </div>
        <div class="form-group d-flex justify-content-between">
            <b-dropdown :text="seasonText">
                <b-dropdown-item v-for="(season, i) of seasons" :key="i" @click="onSeasonSelect"
                                 :data-value="season.id">
                    {{ season.name }}
                </b-dropdown-item>
            </b-dropdown>
            <div class="season-first-purchase-page__buttons">
                <ui-button v-if="seasonValue != 0" @clicked="onSaveClick">
                    Сохранить
                </ui-button>
                <ui-button v-if="seasonValue == -1" @clicked="onCancelClick" class="ml-2">
                    Отмена
                </ui-button>
            </div>

            <div></div>
        </div>
        <template v-if="seasonValue != 0">
            <div class="page__table">
                <div class="page__table-row">
                    <div class="page__table-cell">Название:</div>
                    <div class="page__table-cell justify-content-end mr-0">
                        <input type="text" class="form-control" v-model="seasonText"/>
                    </div>

                </div>
                <div class="page__table-row">
                    <div class="page__table-cell">Статус:</div>
                    <div class="page__table-cell justify-content-end mr-0"><b>{{ status }}</b></div>

                </div>
                <div class="page__table-row">
                    <div class="page__table-cell"><label class="my-0">Период:</label></div>
                    <div class="page__table-cell align-items-center justify-content-end mr-0">
                        <b-form-datepicker v-model="dateStartValue"
                                           class="first-purchase-page__date-picker"/>
                        <span class="mx-2">:</span>
                        <b-form-datepicker v-model="dateEndValue"
                                           class="first-purchase-page__date-picker"/>
                    </div>
                </div>
                <div class="page__table-row">
                    <div class="page__table-cell">Бонус ПП:</div>
                    <div class="page__table-cell justify-content-end mr-0">
                        <b-dropdown :text="firstPurchaseRelationText">
                            <b-dropdown-item v-for="(relation, i) of firstPurchaseRelations" :key="i"
                                             @click="onRelationSelect"
                                             :data-value="relation.id">
                                {{ relation.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>

            <div class="page__table mt-5">
                <div class="page__table-row page__table-row--header">
                    <div class="page__table-cell">Товар</div>
                    <div class="page__table-cell">Множитель</div>
                </div>
                <div class="page__table-row" v-for="product of products" :key="product.id"
                     :data-id="product.id"
                >
                    <div class="page__table-cell">{{ product.google_id }}</div>
                    <div class="page__table-cell justify-content-end">
                        <input type="number" :value="multipliers[product.id]"
                               @input="updateMultiplier(product.id, $event)"
                               min=0 oninput="validity.valid||(value=0)"
                               class="form-control text-right"
                               style="width: 200px;"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import Vue from 'vue';

export default {
    data: () => ({
        pageIsReady: false,
        multipliers: {},
        seasonValue: 0,
        seasonText: '',
        seasons: [
            {id: 0, name: 'Выбрать'},
            {id: 1, name: 'Хеллоуин'},
            {id: 2, name: 'Новый год'},
        ],
        dateStartValue: '',
        dateEndValue: '',
        firstPurchaseRelationText: '',
        firstPurchaseRelationValue: 0,
        firstPurchaseRelations: [
            {id: 0, name: 'Заменяет'},
            {id: 1, name: 'Суммируется'},
            {id: 2, name: 'Уступает'},
        ],
    }),
    components: {
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack')
    },
    computed: {
        products() {
            return this.$store.state.products.list;
        },
        status() {
            if (!this.dateStartValue.length || !this.dateEndValue.length) return 'Неактивно';
            const currentDate = new Date().getTime();
            const isActive = currentDate >= Date.parse(this.dateStartValue) && currentDate <= Date.parse(this.dateEndValue);
            return isActive ? 'Активно' : 'Неактивно';
        }
    },
    async created() {
        await this.getProducts();
        this.initMultipliers();

        this.seasonText = this.seasons[this.seasonValue].name;
        this.firstPurchaseRelationText = this.firstPurchaseRelations[this.firstPurchaseRelationValue].name;

        this.pageIsReady = true;
    },
    methods: {
        ...mapActions('products', ['getProducts']),
        initMultipliers() {
            Object.keys(this.products).forEach(productId => this.multipliers[productId] = 1);
        },
        updateMultiplier(productId, event) {
            Vue.set(this.multipliers, productId, parseInt(event.target.value, 10));
        },
        onAddSeasonClick() {
            const newId = -1;
            this.seasons.push({id: newId, name: 'Новое'});
            this.seasonValue = newId;
            this.seasonText = 'Новое';
            Object.keys(this.multipliers).forEach(key => this.multipliers[key] = 1);
        },
        onSaveClick() {
            if (this.seasonValue == -1) {
                this.seasonValue = this.seasons.length - 1;
                this.seasons[this.seasonValue].id = this.seasonValue > 0 ?
                    this.seasons[this.seasonValue - 1].id + 1 : 1;
            }

            this.seasons[this.seasonValue].name = this.seasonText;
            //todo post save this.seasons[this.seasonValue] + form data
        },
        onCancelClick() {
            this.seasons.pop();
            this.seasonValue = 0;
            this.seasonText = this.seasons[this.seasonValue].name;
        },
        onSeasonSelect(event) {
            this.seasonValue = parseInt(event.target.dataset.value, 10);
            this.seasonText = this.seasons[this.seasonValue].name;
        },
        onRelationSelect(event) {
            this.firstPurchaseRelationValue = parseInt(event.target.dataset.value, 10);
            this.firstPurchaseRelationText = this.firstPurchaseRelations[this.firstPurchaseRelationValue].name;
        },
    }
};
</script>

<style lang="scss">
.season-first-purchase-page {
    .ui-button {
        min-width: 150px;
    }

    .page__table-cell {
        overflow: initial !important;
    }

    .page__table-row {
        .page__table-cell {
            margin: 0 !important;

            &:nth-child(1) {
                flex: 0 1 50% !important;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 0;
    }

    .dropdown-toggle {
        min-width: 200px;
        width: auto !important;
    }
}
</style>
