<template>
    <div class="story-page page">
        <div class="story-page__header d-flex">
            <div class="story-page__header-left">
                <nav-back/>
                {{ title }}
            </div>
            <div class="story-page__header-right">
                <ui-button @clicked="onSaveClick" :color="saveButtonColor"
                    :class="{'story-page__save-button--disabled': !anyChanges}"
                >
                    {{ saveButtonText }}
                </ui-button>
            </div>
        </div>
        <div class="row mt-3 ml-1 font-weight-bold" style="position: relative; margin-bottom: -20px;" v-if="storyId">
            <div class="story-page__story-id" v-b-tooltip.hover title="Скопировать" @click="clipStoryId">
                ID истории: {{ storyId }}
            </div>
        </div>
        <div class="story-page__content d-flex">
            <div class="story-page__story-data d-flex">
                <div class="row mb-4 mr-4">
                    <div class="page-title row">Описание истории</div>
                </div>
                <div class="row">
                    <div class="story-page__image col">
                        <div class="story-page__image-title">Обложка истории</div>
                        <div v-if="catalogImage" :style="{backgroundImage: `url(${catalogImage})`}"
                             class="story-page__image-cover">
                            <img :src="require('../assets/svg/ic_refresh.svg')" width="24" height="24"
                                 class="story-page__image-button" alt="" @click="onAddCatalogImageClick"/>
                            <img :src="require('../assets/svg/ic_trash.svg')" width="24" height="24"
                                 class="story-page__image-button" alt="" @click="onCatalogImageDeleteClick"/>
                        </div>
                        <div v-else class="story-page__image-upload-block" @click="onAddCatalogImageClick">
                            <div class="story-page__image-upload-plus-icon">
                                <div class="d-flex">&plus;</div>
                            </div>
                        </div>
                        <input type="file" accept="image/png" @change="uploadCatalogImage($event)"
                               style="display: none;" ref="catalogImageUploadButton">
                    </div>
                </div>
                <div class="row page__input">
                    <div class="form-group">
                        <label for="storyTitleInput" class="page__input-title">Название</label>
                        <input type="text" class="form-control" id="storyTitleInput" aria-describedby="storyTitleInput"
                               placeholder="Название" v-model="title"
                               :class="{'data-changed': titleChanged}"
                        >
                    </div>
                </div>
                <div class="row page__input">
                    <div class="form-group">
                        <label class="page__input-title">Краткое описание</label>
                        <textarea class="form-control" id="storyDescriptionInput" rows="3"
                                  placeholder="Краткое описание" v-model="description"
                                  :class="{'data-changed': descriptionChanged}"
                        ></textarea>
                    </div>
                </div>
                <div class="row page__input">
                    <div class="form-group">
                        <label class="page__input-title">Вес в рекомендованных</label>
                        <input type="number" v-model.number="recommendedOrder"
                               :class="{'data-changed': recommendedChanged}"
                               class="form-control"
                               min=0 oninput="validity.valid||(value=0)"
                        />
                    </div>
                </div>

                <template v-if="storyId">
                    <label class="story-page__label mt-4" :class="{'data-changed': lastChapterChanged}">
                        Последняя глава
                    </label>
                    <b-form-select v-model="lastChapterId" :options="lastChapterOptions"
                                   class="form-control"/>
                </template>

                <div class="row story-page__sub-row">
                    <div class="col">
                        <div class="form-group">
                            <label class="page__input-title">За кого можно играть</label><br/>
                            <b-dropdown :text="restrictionGenderText" id="storyCharacterRestriction"
                                        class="m-md-2 stories-page__filter-dropdown"
                                        :class="{'data-changed': restrictionGenderChanged}"
                            >
                                <b-dropdown-item v-for="(restriction, i) of restrictionsGender" :key="i"
                                                 :data-value="i"
                                                 @click="onGenderRestrictionChanged"
                                >
                                    {{ restriction.name }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group" style="margin-left: 20px;">
                            <label class="page__input-title">Возрастные ограничения</label><br/>
                            <b-dropdown :text="restrictionAgeText" id="storyAgeRestriction"
                                        class="m-md-2 stories-page__filter-dropdown"
                                        :class="{'data-changed': restrictionAgeChanged}"
                            >
                                <b-dropdown-item v-for="(restriction, i) of restrictionsAge" :key="i"
                                                 :data-value="`${restriction.isAdult}`"
                                                 @click="onAgeRestrictionChanged"
                                >
                                    {{ restriction.name }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <div v-if="storyId" class="row page__input">
                    <div class="form-group">
                        <label class="page__input-title"
                               :class="{'data-changed': categoriesChanged}"
                        >
                            Категории
                        </label><br/>
                        <ui-button v-for="(category, i) of categoriesList" :key="i" color="transparent"
                                   class="story-page__category-button" @clicked="onDeleteCategoryClick(category.id)">
                            {{ category.name }}
                        </ui-button>
                        <ui-button color="transparent" class="page__plus-button" @clicked="onAddCategoryClick">
                            +
                        </ui-button>
                    </div>
                </div>
                <div class="row page__input">
                    <div class="form-group">
                        <label class="page__input-title"
                               :class="{'data-changed': genresChanged}"
                        >
                            Жанры
                        </label><br/>
                        <ui-button v-for="(genre, i) of genresList" :key="i" color="transparent"
                                   class="story-page__category-button" @clicked="onDeleteGenreClick(genre)">
                            {{ genre.name }}
                        </ui-button>
                        <ui-button color="transparent" class="page__plus-button" @clicked="onAddGenreClick">
                            +
                        </ui-button>
                    </div>
                </div>

                <label class="story-page__label mt-4" style="box-shadow: none !important;"
                       :class="{'data-changed': rewardsChanged}">
                    Награды за прохождение истории
                </label>
                <span v-if="!rewards.length" class="mb-3 mt-1" style="font-size: 16px;">Отсутствуют</span>
                <div v-for="(reward) of rewards" :key="reward.currency_id" class="mb-4" @click="onRewardsClick"
                     v-if="typeof selectedRewardOptions[`currency-${reward.currency_id}`] !== undefined"
                >
                    <div class="d-flex flex-row align-items-center">
                        <div class="d-flex flex-column">
                            <b-form-select v-model="selectedRewardOptions[`currency-${reward.currency_id}`]"
                                           :options="currenciesOptions"
                                           class="form-control story-page__reward-input"/>
                            <input type="number" placeholder="Количество"
                                   class="form-control mt-2 story-page__reward-input"
                                   v-model.number="rewardValues[`currency-${reward.currency_id}`]"
                                   min=0 oninput="validity.valid||(value=0)"
                            >
                        </div>
                        <div class="d-flex flex-column">
                            <svg v-if="!isDefaultReward"
                                 xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 v-b-tooltip.hover title="Удалить награду"
                                 @click="deleteReward(reward.currency_id)"
                                 class="bi bi-x-circle ml-4 story-page__reset-reward" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <ui-button color="transparent" @clicked="addReward" class="mb-2" style="max-width: 290px;"
                           v-if="getAvailableCurrencies().length"
                >
                    Добавить награду
                </ui-button>
                <ui-button v-if="!isDefaultReward" color="transparent" @clicked="resetRewardToDefault"
                           style="max-width: 290px;"
                >
                    Сбросить награды на дефолт
                </ui-button>
            </div>
            <div v-if="storyId" class="story-page__chapters-data">
                <div class="story-page__chapters-title mt-4 ml-2">
                    Главы
                </div>
                <ui-button class="story-page__add-chapter-button ml-2" @clicked="onAddChapterClick">
                    Добавить главу
                </ui-button>
                <div v-if="sortedChapters.length" class="story-page__chapters-list">
                    <draggable
                        class="list-group"
                        tag="ul"
                        v-model="sortedChapters"
                        v-bind="chapterDragOptions"
                        @start="chapterDragging = true"
                        @end="chapterDragging = false"
                        :move="onChaptersMoved"
                    >
                        <transition-group type="transition" :name="!chapterDragging ? 'flip-list' : null">
                            <li
                                class="list-group-item story-page__chapter-item"
                                v-for="(chapterData, i) in sortedChapters"
                                :key="chapterData.id"
                            >
                                <chapter-item :chapter-id="chapterData.id" :chapter-number="i + 1"
                                              :story-id="parseInt(storyId, 10)" :chapterData="chapterData"/>
                            </li>
                        </transition-group>
                    </draggable>
                </div>
                <div v-else>
                    <div class="story-page__no-chapters">
                        Глав нет
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {RESTRICTIONS_GENDER} from '@/configs/restrictions';
import {openAddStoryCategoryModal} from '@/modals/addStoryCategoryModal';
import {openAddStoryGenreModal} from '@/modals/addStoryGenreModal';
import {openAddStorySkillModal} from '@/modals/addStorySkillModal';
import bus from '@/plugins/bus';
import draggable from "vuedraggable";
import {findWithAttr} from '@/utils';

const getGenderRestriction = (isForMen, isForWomen) => {
    let res;

    Object.entries(RESTRICTIONS_GENDER).forEach(([key, value]) => {
        if (value.isForMen === isForMen && value.isForWomen === isForWomen) {
            res = key;
        }
    });

    return res;
};

function DuplicateRewardException(currencyId) {
    this.currencyId = currencyId;
}

export default {
    props: ['storyId'],
    data: () => ({
        title: 'Новая история',
        description: 'Краткое описание',
        availableOnProduction: false,
        restrictionGenderOption: 'F&M',
        restrictionAgeOption: false,
        catalogImage: '',
        coverImage: '',
        isAdult: false,
        rewards: [],
        recommendedOrder: 0,

        anyChanges: false,
        dataFetched: false,

        storyData: Object,

        // data changes
        titleChanged: false,
        descriptionChanged: false,
        restrictionGenderChanged: false,
        restrictionAgeChanged: false,
        categoriesChanged: false,
        genresChanged: false,
        skillsChanged: false,
        publishVariantChanged: false,
        publishDayChanged: false,
        storyStatusChanged: false,
        rewardsChanged: false,
        lastChapterChanged: false,
        recommendedChanged: false,

        chaptersList: [],
        chapterDragging: false,

        restrictionsAge: [
            {isAdult: false, name: 'Без ограничений'},
            {isAdult: true, name: '18+'},
        ],

        tempGenres: [],
        tempSkills: [],

        chaptersDragLocked: false,

        currenciesOptions: [
            {
                value: 0,
                text: 'Ожидание'
            }
        ],
        selectedRewardOptions: {},
        rewardValues: {},

        isDefaultReward: true,

        lastChapterId: null
    }),
    components: {
        draggable,
        NavBack: () => import('../components/navigation/NavBack'),
        UiButton: () => import('../components/ui/UiButton'),
        ChapterItem: () => import('../components/stories/ChapterItem'),
    },
    computed: {
        ...mapState(['configs']),
        ...mapState('stories', ['chaptersSortingOrder', 'storyStatuses']),
        ...mapGetters('stories', ['getStoryCategories', 'getStoryGenres', 'getStorySkills', 'getStoryChapters',
            'getChapterById']),
        ...mapGetters('genres', ['getGenreById']),
        ...mapGetters('categories', ['getCategoryById']),
        stories() {
            return this.$store.state.stories.list;
        },
        genres() {
            return this.$store.state.genres.list;
        },
        categories() {
            return this.$store.state.categories.list;
        },
        currencies() {
            return this.$store.state.currencies.list;
        },
        intStoryId() {
            return parseInt(this.storyId, 10);
        },
        lastChapterOptions() {
            const chapters = this.getStoryChapters(this.intStoryId);
            return [{text: 'Не установлена', value: 0}, ...chapters.map(chapter => {
                return {
                    text: chapter.title,
                    value: chapter.id
                };
            })];
        },
        sortedChapters: {
            get() {
                const chapters = this.getStoryChapters(this.intStoryId);

                if (!chapters || !chapters.length) return [];

                let order = this.chaptersSortingOrder[this.intStoryId] || false;

                if (!order) {
                    order = chapters.map(chapter => chapter.id);
                    this.$store.commit('stories/UPDATE_CHAPTERS_SORTING_ORDER', {storyId: this.intStoryId, order});
                }

                let sortedChapters = [];

                order.forEach(chapterId => {
                    const config = this.getChapterById(this.intStoryId, chapterId);

                    if (config) {
                        sortedChapters.push(config);
                    }
                });

                return sortedChapters;
            },
            async set(value) {
                const orderList = value.map(chapter => chapter.id);

                this.chaptersDragLocked = true;

                for (let i = 0; i < orderList.length; i++) {
                    await this.updateChapterOrder({
                        storyId: this.intStoryId,
                        chapterId: orderList[i],
                        chapterOrder: i + 1,
                        fullOrder: orderList
                    });
                }

                this.chaptersDragLocked = false;

                bus.$emit('show-notification-message', 'Сортировка глав обновлена');
            }
        },
        chapterDragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        saveButtonText() {
            return this.anyChanges ? 'Сохранить' : 'Сохранено';
        },
        saveButtonColor() {
            return this.anyChanges ? 'blue' : 'transparent';
        },
        restrictionsGender() {
            return RESTRICTIONS_GENDER;
        },
        restrictionAgeText() {
            const config = this.restrictionsAge.find(restriction => restriction.isAdult === this.restrictionAgeOption);
            return config ? config.name : '';
        },
        restrictionGenderText() {
            const config = RESTRICTIONS_GENDER[this.restrictionGenderOption];
            return config ? config.name : '';
        },
        genresList() {
            if (this.storyId) {
                return this.getStoryGenres(this.storyId);
            } else {
                return this.tempGenres;
            }
        },
        categoriesList() {
            return this.storyId ? this.getStoryCategories(this.storyId) : [];
        }
    },
    watch: {
        lastChapterId() {
            this.changesCommonHandler('lastChapter');
        },
        title: {
            handler() {
                this.changesCommonHandler('title');
            }
        },
        description: {
            handler() {
                this.changesCommonHandler('description');
            }
        },
        restrictionGenderOption: {
            handler() {
                this.changesCommonHandler('restrictionGender');
            }
        },
        restrictionAgeOption: {
            handler() {
                this.changesCommonHandler('restrictionAge');
            }
        },
        coverImage(value, oldValue) {
            if (oldValue) {
                this.anyChanges = true;
            }
        },
        catalogImage(value, oldValue) {
            if (oldValue) {
                this.anyChanges = true;
            }
        },
        recommendedOrder() {
            this.changesCommonHandler('recommended');
        }
    },
    created() {
        bus.$off('story-genres-changed').$on('story-genres-changed', this.onGenresChanged);
        bus.$off('story-categories-changed').$on('story-categories-changed', this.onCategoriesChanged);
        bus.$off('story-skills-changed').$on('story-skills-changed', this.onSkillsChanged);
    },
    mounted() {
        this.fetchData();
    },
    beforeDestroy() {
        bus.$off('story-categories-changed');
        bus.$off('story-genres-changed');
        bus.$off('story-skills-changed');
    },
    methods: {
        ...mapActions(['getConfigs']),
        ...mapActions('stories', ['getStories', 'getChapters', 'deleteStory', 'saveStory', 'updateChapterOrder',
            'deleteStoryFromCategory']),
        ...mapActions('genres', ['getGenres']),
        ...mapActions('categories', ['getCategories']),
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('skills', ['getSkills']),
        ...mapMutations('stories', ['DELETE_STORY_GENRE', 'DELETE_STORY_SKILL', 'DELETE_STORY_CATEGORY']),
        async fetchData() {
            await this.getConfigs();
            await this.fetchStories();
            await this.getCategories();
            await this.getGenres();
            await this.getCurrencies();
            await this.getSkills();

            setTimeout(() => {
                this.dataFetched = true;
            }, 250);

            this.fillSelects();
        },
        fillSelects() {
            this.currenciesOptions = this.currencies
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });

            if (this.rewards.length && !this.isDefaultReward) {
                this.rewards.forEach(reward => {
                    this.selectedRewardOptions[`currency-${reward.currency_id}`] = reward.currency_id;
                    this.rewardValues[`currency-${reward.currency_id}`] = reward.value;
                });
            } else if (this.isDefaultReward) {
                this.rewards = [{
                    currency_id: this.configs.story_reward_currency_id,
                    value: this.configs.story_reward_currency_value
                }];
                this.selectedRewardOptions[`currency-${this.configs.story_reward_currency_id}`] = this.configs.story_reward_currency_id;
                this.rewardValues[`currency-${this.configs.story_reward_currency_id}`] = this.configs.story_reward_currency_value;
            }

            this.lastChapterId = this.storyData.last_chapter_id || 0;
        },
        onGenresChanged(genreId) {
            if (!this.storyId) {
                this.tempGenres.push(this.getGenreById(genreId));
            }

            this.anyChanges = true;
            this.genresChanged = true;
        },
        onCategoriesChanged(categoryId) {
            if (!this.storyId) {
                this.tempCategories.push(this.getCategoryById(categoryId));
            }

            this.anyChanges = true;
            this.categoriesChanged = true;
        },
        onSkillsChanged(skillId) {
            if (!this.storyId) {
                this.tempSkills.push(this.getSkillById(skillId));
            }

            this.anyChanges = true;
            this.skillsChanged = true;
        },
        changesCommonHandler(fieldName) {
            if (!this.dataFetched) {
                return;
            }

            this.anyChanges = true;

            this[`${fieldName}Changed`] = true;
        },
        async fetchStories() {
            await this.getStories();
            await this.getChapters(this.intStoryId);

            if (this.storyId) {
                this.storyData = this.stories.find(storyData => storyData.id === this.intStoryId);

                this.title = this.storyData.title;
                this.description = this.storyData.description;
                this.catalogImage = this.storyData.url_image_small;
                this.coverImage = this.storyData.url_image_big;
                this.restrictionAgeOption = this.storyData.is_adult;
                this.restrictionGenderOption = getGenderRestriction(this.storyData.is_man, this.storyData.is_woman);
                this.rewards = this.storyData.rewards || [];
                this.isDefaultReward = this.storyData.reward_default;
                this.recommendedOrder = this.storyData.order_recommended || 0;
            } else {
                this.setDefaultReward();
            }
        },
        onAgeRestrictionChanged(event) {
            this.restrictionAgeOption = event.currentTarget.dataset.value === 'true';
        },
        onGenderRestrictionChanged(event) {
            this.restrictionGenderOption = event.currentTarget.dataset.value;
        },
        async onSaveClick() {
            if (!this.anyChanges) {
                return false;
            }

            let genres;

            if (this.storyId) {
                genres = this.getStoryGenres(this.storyId).map(genre => genre.id);
            } else {
                genres = this.tempGenres.map(genre => genre.id);
            }

            const chapters = this.chaptersList;

            const genderConfig = RESTRICTIONS_GENDER[this.restrictionGenderOption];

            let data = {
                title: this.title,
                description: this.description,
                is_man: genderConfig.isForMen,
                is_woman: genderConfig.isForWomen,
                is_adult: this.restrictionAgeOption,
                order_recommended: parseInt(this.recommendedOrder, 10),
                genres
            };

            if (this.catalogImage.indexOf(';base64,') > -1) {
                data.image_small = this.catalogImage.replace(/^data:.+;base64,/, '');
            }

            if (this.coverImage.indexOf(';base64,') > -1) {
                data.image_big = this.coverImage.replace(/^data:.+;base64,/, '');
            }

            if (this.storyId) {
                data.id = this.intStoryId;

                if (chapters.length) {
                    data.chapters = chapters;
                }

                data.last_chapter_id = this.lastChapterId || null; // null - не установлена
            }

            if (this.rewardsChanged) {
                let rewardsCounter = {};

                data.rewards = [];

                Object.entries(this.selectedRewardOptions).forEach(([key, currencyId]) => {
                    data.rewards.push({
                        currency_id: currencyId,
                        value: parseInt(this.rewardValues[key], 10)
                    });

                    if (!rewardsCounter[currencyId])
                        rewardsCounter[currencyId] = 1;
                    else
                        rewardsCounter[currencyId]++;
                });

                try {
                    Object.entries(rewardsCounter).forEach(([currencyId, count]) => {
                        if (count > 1)
                            throw new DuplicateRewardException(parseInt(currencyId), 10);
                    });
                } catch (e) {
                    if (!e.currencyId) {
                        throw e;
                    } else {
                        const currency = this.currencies.find(c => c.id === e.currencyId);
                        bus.$emit('show-notification-message', `Дублирующаяся награда: ${currency.name}`);
                        return;
                    }
                }

                data.reward_default = this.isDefaultReward = false;
            } else if (!this.storyId) {
                data.rewards = [];
                data.reward_default = true;
            }

            const res = await this.saveStory(data);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Сохранено');

                this.anyChanges = false;
                this.resetChangedFlags();

                if (res.redirectStory) {
                    await this.$router.push(`/story/${res.redirectStory}`);
                }
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        deleteReward(currencyId) {
            this.rewards = this.rewards.filter(r => r.currency_id !== currencyId);
            delete this.selectedRewardOptions[`currency-${currencyId}`];
            delete this.rewardValues[`currency-${currencyId}`];
            !this.rewards.length && this.isDefaultReward && this.setDefaultReward();
        },
        setDefaultReward() {
            this.rewards = [{
                currency_id: this.configs.story_reward_currency_id,
                value: this.configs.story_reward_currency_value,
            }];
            this.selectedRewardOptions = {
                [`currency-${this.configs.story_reward_currency_id}`]: this.configs.story_reward_currency_id
            };
            this.rewardValues = {
                [`currency-${this.configs.story_reward_currency_id}`]: this.configs.story_reward_currency_value
            };
            this.isDefaultReward = true;
        },
        addReward() {
            const availableCurrencies = this.getAvailableCurrencies();

            if (!availableCurrencies.length)
                return;

            this.rewards.push({
                currency_id: availableCurrencies[0].id,
                value: 0
            });
            this.selectedRewardOptions[`currency-${availableCurrencies[0].id}`] = availableCurrencies[0].id;
            this.rewardValues[`currency-${availableCurrencies[0].id}`] = 0;

            this.isDefaultReward = false;
        },
        async resetRewardToDefault() {
            const res = await this.saveStory({
                id: this.intStoryId,
                rewards: [],
                reward_default: true
            });

            if (!res.error) {
                bus.$emit('show-notification-message', 'Награда сброшена на дефолт');

                this.setDefaultReward();
                this.rewardsChanged = false;
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        resetChangedFlags() {
            this.titleChanged = false;
            this.descriptionChanged = false;
            this.restrictionGenderChanged = false;
            this.restrictionAgeChanged = false;
            this.genresChanged = false;
            this.rewardsChanged = false;
            this.lastChapterChanged = false;
        },
        async onDeleteClick() {
            await this.deleteStory(this.intStoryId);
            await this.$router.push('/stories');
        },
        async onAddChapterClick() {
            await this.$router.push({
                name: 'chapters',
                params: {chapterId: 0, storyId: this.intStoryId, chapterData: null},
            });
        },
        onAddCategoryClick() {
            openAddStoryCategoryModal(this.intStoryId, this.tempCategories);
        },
        async onDeleteCategoryClick(categoryId) {
            const res = await this.deleteStoryFromCategory({
                storyId: this.intStoryId,
                categoryId: categoryId
            });
            this.categoriesChanged = true;
            this.anyChanges = true;
        },
        onAddGenreClick() {
            openAddStoryGenreModal(this.intStoryId, this.tempGenres);
        },
        onDeleteGenreClick(genre) {
            if (this.tempGenres.length) {
                const idx = findWithAttr(this.tempGenres, 'id', genre.id);

                if (idx > -1) {
                    this.tempGenres.splice(idx, 1);
                }
            }

            this.DELETE_STORY_GENRE({storyId: this.intStoryId, genreId: genre.id});
            this.genresChanged = true;
            this.anyChanges = true;
        },

        onAddSkillClick() {
            openAddStorySkillModal(this.intStoryId, this.tempSkills);
        },
        onDeleteSkillClick(skill) {
            if (this.tempSkills.length) {
                const idx = findWithAttr(this.tempSkills, 'id', skill.id);

                if (idx > -1) {
                    this.tempSkills.splice(idx, 1);
                }
            }

            this.DELETE_STORY_SKILL({storyId: this.intStoryId, skillId: skill.id});
            this.skillsChanged = true;
            this.anyChanges = true;
        },

        onAddCoverImageClick() {
            if (!this.$refs.coverImageUploadButton) return;
            this.$refs.coverImageUploadButton.click();
        },
        uploadCoverImage(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = event => {
                this.coverImage = event.target.result;
            };
        },
        onCoverImageDeleteClick() {
            this.coverImage = '';
        },

        onAddCatalogImageClick() {
            if (!this.$refs.catalogImageUploadButton) return;
            this.$refs.catalogImageUploadButton.click();
        },
        uploadCatalogImage(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = event => {
                this.catalogImage = event.target.result;
            };
        },
        onCatalogImageDeleteClick() {
            this.catalogImage = '';
        },

        onChaptersMoved() {
            return !this.chaptersDragLocked;
        },

        getAvailableCurrencies() {
            const usedCurrencies = Object.keys(this.selectedRewardOptions)
                .map(c => parseInt(c.split('currency-')[1], 10));
            return this.currencies.filter(c => usedCurrencies.indexOf(c.id) === -1 && c.id > 0);
        },
        onRewardsClick() {
            this.anyChanges = true;
            this.rewardsChanged = true;
        },

        clipStoryId() {
            this.$clipboard(this.storyId);
            bus.$emit('show-notification-message', 'ID скопирован в буфер обмена');
        }
    }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";
@import "../assets/scss/mixins";

.story-page {
    &__story-id {
        font-size: 16px;
        user-select: text;
        text-decoration: underline;
        cursor: pointer;
    }

    &__header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //height: 80px;
        padding: 12px 0;
        position: relative;
        background: transparent;

        &:after {
            content: '';
            width: 200vw;
            height: 1px;
            background: #9AAABC;
            position: absolute;
            bottom: 0;
            left: -100vw;
        }
    }

    &__label {
        font-size: 16px;
    }

    &__content {
        padding-top: 16px;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
    }

    &__story-data {
        flex-direction: column;
        width: 60vw;
        padding-bottom: 175px;

        .row {
            font-size: 24px;
            margin: 12px 0 0;
        }

        .col {
            padding: 0;
            font-size: 16px;
            max-width: 270px;
        }
    }

    &__chapters-data {
        width: 40vw;
        margin-left: 66px;

        .dropdown-toggle {
            width: auto;
            min-width: 212px;
        }

        .page__input-title {
            margin: 24px 0 16px 0;
            font-size: 16px;
        }
    }

    &__chapter-item {
        border: 0;
    }

    &__header-left,
    &__header-right {
        //max-width: 556px; todo check for troubles
        text-align: left;
    }

    &__header-left {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        text-align: left;
        display: flex;
        flex-direction: row;
    }

    &__header-right {
        display: flex;
        align-items: center;

        .btn {
            background: transparent;
            color: $primaryColor;
            font-size: 16px;
            border: 1px solid $primaryColor;
            margin-right: 12px;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }

    &__status-text {
        font-weight: 700;
        font-size: 16px;
        margin-right: 12px;
    }

    &__image {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        margin: 0 16px 16px 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &__image-title {
        margin-bottom: 16px;
    }

    &__image-cover {
        width: 180px;
        height: 237px;
        background-size: cover;
        border-radius: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 8px;
    }

    &__image-button {
        margin: 0 4px;
        cursor: pointer;
    }

    .form-control {
        width: 560px;
        border-color: $primaryColor;
        color: $primaryColor;
    }

    &__input-title {
        font-size: 16px;
    }

    &__sub-row {
        .col {
            width: 50%;
        }

        .form-control {
            width: 272px;
        }
    }

    &__delete-button {
        width: 212px;
        height: 40px;
    }

    &__add-chapter-button {
        width: 197px;
        height: 40px;
        margin-top: 54px;
        text-transform: uppercase;
    }

    &__no-chapters {
        font-size: 14px;
        margin-top: 48px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -25px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $primaryColor;

        }
    }

    &__chapters-list {
        margin-top: 36px;
    }

    &__category-button {
        margin-right: 8px;
        margin-top: 8px;
    }

    &__status-dropdown {
        .dropdown-toggle {
            width: auto;
            min-width: 120px;
        }
    }

    .dropdown-toggle {
        padding-right: 24px;
    }

    &__image-upload-block {
        background: #C3DFFF;
        width: 272px;
        height: 160px;
        border-radius: 16px;
        position: relative;
        display: flex;
        justify-self: center;
        align-items: center;
        cursor: pointer;
    }

    &__image-upload-plus-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $primaryColor;
        color: #fff;
        position: absolute;
        bottom: 16px;
        right: 16px;
        display: flex;
        font-size: 24px;
        align-items: center;
        justify-content: center;
    }

    &__filter-dropdown {
        .btn {
            border: 1px solid $primaryColor;
            border-color: $primaryColor !important;
        }
    }

    &__save-button {
        &--disabled {
            pointer-events: none;
        }
    }

    &__reward-input {
        max-width: 270px;
    }

    &__reset-reward {
        cursor: pointer;
    }
}

.data-changed {
    #story-page-status-dropdown__BV_toggle_ {
        border: 0 !important;
    }
}
</style>
