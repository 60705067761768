import Vue from 'vue';

import {UPDATE, update} from '../utils';
import {findWithAttr} from '@/utils';

import api from '@/api';

const state = {
    list: []
};

const getters = {};

const actions = {
    update,
    
    async getAuthors({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/authors').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addAuthor({commit}, data) {
        return new Promise(resolve => {
            api.post('/authors', data).then(author => {
                commit('UPDATE_AUTHOR', author);
                resolve(author);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async updateAuthor({commit}, data) {
        return new Promise(resolve => {
            api.put(`/authors/${data.id}`, data).then(author => {
                commit('UPDATE_AUTHOR', author);
                resolve(author);
            }).catch(error => {
                resolve({error});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    UPDATE_AUTHOR(state, author) {
        let idx = findWithAttr(state.list, 'id', author.id);
    
        if (idx > -1) {
            Vue.set(state.list, idx, author);
        } else {
            Vue.set(state.list, state.list.length, author);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
