import Popup from "@/lib/Popup";
import EditSkillModal from '@/components/modals/EditSkillModal';

export const openEditSkillModal = skill => {
    const popup = new Popup({
        component: EditSkillModal,
        props: {skill},
        params: {
            transition: 'scale',
            name: 'edit-skills-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openEditSkillsModal: openEditSkillModal};
