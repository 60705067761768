import Popup from "@/lib/Popup";
import ChangeUserPasswordModal from '@/components/modals/ChangeUserPasswordModal';

export const openChangeUserPasswordModal = user => {
    const popup = new Popup({
        component: ChangeUserPasswordModal,
        props: {user},
        params: {
            transition: 'scale',
            name: 'change-user-password-modal',
            width: '100%',
            height: '100%',
            classes: 'change-user-password-modal__overlay'
        }
    });
    
    popup.show();
};

export default {openChangeUserPasswordModal};
