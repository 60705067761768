<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onAddClick">
        <div class="mb-3 modal-popup__title">
            Добавление характеристики

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <label class="add-skills-modal__label">Название</label>
        <input type="text" class="form-control mb-2" placeholder="Введите название" v-model="name" ref="input">
        <label class="add-skills-modal__label">Описание</label>
        <textarea class="form-control mb-2" placeholder="Введите описание" v-model="description"></textarea>
        <label class="add-skills-modal__label">Шаг роста</label>
        <input type="number" class="form-control mb-2" placeholder="Шаг роста" v-model="growingStep">
        <label class="add-skills-modal__label">Кап роста</label>
        <input type="number" class="form-control mb-2" placeholder="Кап роста" v-model="growingCapacity">
        <ui-button class="mt-3 mb-2" @clicked="onAddClick">Добавить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    data: () => ({
        name: '',
        description: '',
        growingStep: 0,
        growingCapacity: 0,
        alert: '',
        canReset: false
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    mounted() {
        this.$refs.input.focus();
    },
    watch: {
        growingStep(value) {
            this.growingStep = parseInt(value, 10);
        },
        growingCapacity(value) {
            this.growingCapacity = parseInt(value, 10);
        }
    },
    methods: {
        ...mapActions('skills', ['addSkills']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onAddClick() {
            if (!this.name) {
                this.setAlert('Необходимо ввести название');
                return;
            }

            if (!this.description) {
                this.setAlert('Необходимо ввести описание');
                return;
            }

            if (!this.growingStep || isNaN(this.growingStep)) {
                this.setAlert('Необходимо задать шаг роста');
                return;
            }

            if (!this.growingCapacity || isNaN(this.growingCapacity)) {
                this.setAlert('Необходимо задать кап роста');
                return;
            }

            if (parseInt(this.growingStep, 10) > parseInt(this.growingCapacity, 10)) {
                this.setAlert('Шаг не может быть больше капа');
                return;
            }

            const res = await this.addSkills({
                name: this.name,
                description: this.description,
                growing_step: parseInt(this.growingStep, 10),
                growing_capacity: parseInt(this.growingCapacity, 10)
            });

            if (res.error) {
                this.setAlert(`Ошибка: ${res.error.toString()}`);
            } else {
                this.close();
            }
        },
        close() {
            this.$modal.hide('add-skills-modal');
        }
    }
};
</script>

<style lang="scss">
.add-skills-modal {
    &__label {
        float: left;
    }
}
</style>
