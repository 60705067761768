import Popup from "@/lib/Popup";
import AddMultiPackModal from '@/components/modals/AddMultiPackModal';

export const openMultiPackModal = () => {
    const popup = new Popup({
        component: AddMultiPackModal,
        props: {},
        params: {
            transition: 'scale',
            name: 'add-multi-pack-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openMultiPackModal};
