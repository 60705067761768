import Vue from 'vue';

import {UPDATE, update} from '../utils';
import {clone, findWithAttr, sortByAttrAsc} from '@/utils';

import api from '@/api';
import apiMock from '@/api/mock';
import store from '@/store';

const state = {
    list: [],
    
    /**
     * {storyID => [...chapterIDs]}
     */
    chaptersSortingOrder: {},
    
    storyStatuses: [
        {id: 1, value: 'Ожидает'},
        {id: 2, value: 'На проде'},
    ],
    readingTime: [
        {time: 300, text: '5 минут'},
        {time: 600, text: '10 минут'},
        {time: 900, text: '15 минут'},
    ],
};

const getters = {
    getStoryById: state => storyId => state.list.find(story => story.id === storyId),
    
    getStoryCategories: state => storyId => {
        const storyConfig = state.list.find(story => story.id === parseInt(storyId, 10));
        const storyCategories = storyConfig ? storyConfig.categories : [];
        let categoriesData = [];
    
        storyCategories.forEach(categoryData => {
            const config = store.state.categories.list.find(category => category.id === categoryData.id);
            if (config) {
                categoriesData.push({
                    id: config.id,
                    name: config.name
                });
            }
        });
    
        return categoriesData;
    },
    
    getStoryGenres: state => storyId => {
        const storyConfig = state.list.find(story => story.id === parseInt(storyId, 10));
        const genres = storyConfig ? storyConfig.genres : [];
        let genresData = [];
        
        genres.forEach(genreId => {
            const config = store.state.genres.list.find(genre => genre.id === genreId);
            
            genresData.push({
                id: genreId,
                name: config ? config.name : ''
            });
        });
        
        return genresData;
    },
    
    getStorySkills: state => storyId => {
        const storyConfig = state.list.find(story => story.id === parseInt(storyId, 10));
        let skills = storyConfig ? storyConfig.skills : [];
        let skillData = [];
    
        if (!skills)
            skills = [];
        
        skills.forEach(skillId => {
            const config = store.state.skills.list.find(skill => skill.id === skillId);
        
            skillData.push({
                id: skillId,
                name: config ? config.name : ''
            });
        });
    
        return skillData;
    },
    
    getStoryChapters: state => storyId => {
        const storyConfig = state.list.find(story => story.id === parseInt(storyId, 10));
        return storyConfig ? storyConfig.chapters : [];
    },
    
    getCategoryOrder: state => categoryId => {
        const categoryOrder = state.list
            .filter(story => story.categories.find(category => category.id === categoryId))
            .map(story => {
                const config = story.categories.find(category => category.id === categoryId);
                
                return {
                    story_id: story.id,
                    order: config.order
                };
            });
    
        return sortByAttrAsc(categoryOrder, 'order').map(config => config.story_id);
    },
    
    getNextCategoryOrder: state => categoryId => {
        let maxOrder = 0;
    
        state.list.forEach(story => {
            const config = story.categories.find(category => category.id === categoryId);
        
            if (config && config.order > maxOrder) {
                maxOrder = config.order;
            }
        });
    
        return maxOrder + 1;
    },
    
    getNextChapterOrder: state => storyId => {
        const story = state.list.find(story => story.id === storyId);
        let order = 0;
    
        if (story && story.chapters) {
            story.chapters.forEach(chapter => {
                if (chapter.order > order) {
                    order = chapter.order;
                }
            });
        }
    
        return order + 1;
    },
    
    getChapterById: state => (storyId, chapterId) => {
        const story = state.list.find(story => story.id === storyId);
        
        if (story) {
            const chapter = story.chapters.find(chapter => chapter.id === chapterId);
            return chapter || false;
        }
        
        return false;
    }
};

const actions = {
    update,
    
    async getStories({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/stories').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async deleteStory({commit}, storyId) {
        return new Promise(resolve => {
            apiMock.delete(`/stories/${storyId}`).then(storyId => {
                commit('DELETE_STORY', storyId);
                resolve(storyId);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
        
    },
    
    async saveStory({commit}, storyData) {
        return new Promise(resolve => {
            if (storyData.id) {
                api.patch(`/stories/${storyData.id}`, storyData)
                    .then(story => {
                        commit('SAVE_STORY', story);
                        resolve(story);
                    })
                    .catch(error => {
                        resolve({error});
                    });
            } else {
                api.post('/stories', storyData)
                    .then(story => {
                        commit('SAVE_STORY', story);
                        resolve({...story, redirectStory: story.id});
                    })
                    .catch(error => {
                        resolve({error});
                    });
            }
        });
    },
    
    async getChapters({commit, getters}, storyId) {
        return new Promise(resolve => {
            if (!storyId) {
                resolve([]);
                return;
            }
            
            const story = getters.getStoryById(storyId);
            
            if (story && story.chapters && story.chapters.length) {
                resolve(story.chapters);
                return;
            }
            
            api.fetch(`/stories/${storyId}/chapters`).then(chapters => {
                commit('ADD_CHAPTER', {storyId, chapters});
                resolve(chapters);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async createStoryChapter({commit}, {storyId, chapterData}) {
        return new Promise(resolve => {
            api.post(`/stories/${storyId}/chapters`, chapterData)
                .then(chapterData => {
                    commit('UPDATE_CHAPTERS', {storyId, chapterId: chapterData.id, chapterData});
                    commit('ADD_CHAPTER_ORDER', {storyId, chapterId: chapterData.id});
                    resolve(chapterData);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async updateStoryChapter({commit}, {storyId, chapterData}) {
        return new Promise(resolve => {
            api.patch(`/chapters/${chapterData.id}`, chapterData)
                .then(chapterData => {
                    commit('UPDATE_CHAPTERS', {storyId, chapterId: chapterData.id, chapterData});
                    commit('SET_STORY_PUBLISHED_STATE', {storyId, isPublished: chapterData.is_published});
                    resolve(chapterData);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async deleteStoryChapter({commit}, {storyId, chapterId}) {
        return new Promise(resolve => {
            apiMock.delete(`/chapters/${chapterId}`, {story_id: storyId})
                .then(response => {
                    commit('DELETE_CHAPTER', {storyId, chapterId});
                    resolve(response);
                })
                .catch(_ => {
                    resolve({error: 1});
                });
        });
    },
    
    async addStoryToCategory({state, getters, commit}, {storyId, categoryId}) {
        return new Promise(resolve => {
            commit('ADD_STORY_CATEGORY', {storyId, categoryId, getters});
    
            let stories = getters.getCategoryOrder(categoryId);
    
            if (!stories.length) {
                resolve({error: 'no order config'});
                return;
            }
    
            api.put(`/categories/${categoryId}/stories`, {stories})
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async deleteStoryFromCategory({state, getters, commit}, {storyId, categoryId}) {
        return new Promise(resolve => {
            commit('DELETE_STORY_CATEGORY', {storyId, categoryId, getters});
            
            let stories = getters.getCategoryOrder(categoryId);
            
            if (!stories.length) {
                resolve({error: 'no order config'});
                return;
            }
            
            api.put(`/categories/${categoryId}/stories`, {stories})
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async updateCategoryOrder({}, {categoryId, stories}) {
        return new Promise(resolve => {
            api.put(`/categories/${categoryId}/stories`, {stories})
                .then(response => {
                    resolve(response === 'success' ? 'success' : {error: 'unknown error'});
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async updateChapterOrder({commit}, {storyId, chapterId, chapterOrder, fullOrder}) {
        return new Promise(resolve => {
            api.patch(`/chapters/${chapterId}`, {order: chapterOrder})
                .then(chapter => {
                    commit('UPDATE_CHAPTERS_SORTING_ORDER', {storyId, order: fullOrder});
                    resolve(chapter);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async updateRecommendedOrder({commit}, sortOrders) {
        return new Promise(resolve => {
            api.patch('/stories', {sort_orders: sortOrders})
                .then(resolve({success: 1}))
                .catch(error => {
                    resolve({error});
                });
        });
    }
};

const mutations = {
    UPDATE,
    
    ADD_STORY_CATEGORY(state, {storyId, categoryId, getters}) {
        const stories = clone(state.list);
        let story = stories.find(story => story.id === storyId);
        if (!story) return;
        if (!story.categories) story.categories = [];
        if (story.categories.includes(categoryId)) return;
    
        story.categories.push({
            id: categoryId,
            order: getters.getNextCategoryOrder(categoryId)
        });
        state.list = stories;
    },
    DELETE_STORY_CATEGORY(state, {storyId, categoryId}) {
        const stories = clone(state.list);
        let story = stories.find(story => story.id === storyId);
        if (!story || !story.categories.length) return;
        story.categories = story.categories.filter(categoryData => categoryData.id !== categoryId);
        state.list = stories;
    },
    
    ADD_STORY_GENRE(state, {storyId, genreId}) {
        const stories = clone(state.list);
        
        let story = stories.find(story => story.id === storyId);
        
        if (!story) {
            return;
        }
        
        if (story.genres.includes(genreId)) {
            return;
        }
        
        story.genres.push(genreId);
        
        state.list = stories;
    },
    DELETE_STORY_GENRE(state, {storyId, genreId}) {
        const stories = clone(state.list);
        
        let story = stories.find(story => story.id === storyId);
        
        if (!story) {
            return;
        }
        
        const genreIndex = story.genres.indexOf(genreId);
        story.genres.splice(genreIndex, 1);
        
        state.list = stories;
    },
    
    ADD_STORY_SKILL(state, {storyId, skillId}) {
        const stories = clone(state.list);
        
        let story = stories.find(story => story.id === storyId);
        
        if (!story) {
            return;
        }
        
        if (!story.skills)
            story.skills = [];
        
        if (story.skills.includes(skillId)) {
            return;
        }
        
        story.skills.push(skillId);
        
        state.list = stories;
    },
    DELETE_STORY_SKILL(state, {storyId, skillId}) {
        const stories = clone(state.list);
        
        let story = stories.find(story => story.id === storyId);
        
        if (!story)
            return;
        
        const skillIndex = story.skills.indexOf(skillId);
        story.skills.splice(skillIndex, 1);
        
        state.list = stories;
    },
    
    DELETE_STORY(state, storyId) {
        const stories = clone(state.list);
        let storyIndex = 0;
        
        stories.forEach((story, idx) => {
            if (story.id === storyId) {
                storyIndex = idx;
            }
        });
    
        Vue.delete(state.list, storyIndex);
    },
    SAVE_STORY(state, storyData) {
        const stories = clone(state.list);
        let story = stories.find(story => story.id === storyData.id);
        
        if (story) {
            Object.entries(storyData).forEach(([key, value]) => {
                story[key] = value;
            });
        } else {
            stories.push(storyData);
        }
        state.list = stories;
    },
    
    ADD_CHAPTER(state, {storyId, chapters}) {
        let stories = clone(state.list);
        let story = stories.find(story => story.id === storyId);
        
        if (!story.chapters) {
            story.chapters = [];
        }
        
        chapters.forEach(chapter => {
            story.chapters.push(chapter);
        });
        
        state.list = stories;
    },
    
    UPDATE_CHAPTERS(state, {storyId, chapterData}) {
        let stories = clone(state.list);
        let story = stories.find(story => story.id === storyId);
        
        if (!story.chapters) {
            story.chapters = [];
        }
        
        let found = false;
        
        for (let i = 0; i < story.chapters.length; i++) {
            if (story.chapters[i].id === chapterData.id) {
                found = true;
                story.chapters[i] = chapterData;
                break;
            }
        }
        
        if (!found) {
            story.chapters.push(chapterData);
        }
        
        state.list = stories;
    },
    DELETE_CHAPTER(state, {storyId, chapterId}) {
        let stories = clone(state.list);
        let story = stories.find(story => story.id === storyId);
        
        if (story) {
            let chapterIndex = 0;
            
            for (let i = 0; i < story.chapters.length; i++) {
                if (story.chapters[i].id === chapterId) {
                    chapterIndex = i;
                    break;
                }
            }
    
            story.chapters.splice(chapterIndex, 1);
    
            state.list = stories;
        }
    },
    
    UPDATE_CHAPTERS_SORTING_ORDER(state, {storyId, order}) {
        Vue.set(state.chaptersSortingOrder, storyId, order);
    },
    
    ADD_CHAPTER_ORDER(state, {storyId, chapterId}) {
        const order = state.chaptersSortingOrder[storyId] ? clone(state.chaptersSortingOrder[storyId]) : [];
        order.push(chapterId);
        Vue.set(state.chaptersSortingOrder, storyId, order);
    },
    
    SET_STORY_PUBLISHED_STATE(state, {storyId, isPublished}) {
        const story = state.list.find(story => story.id === storyId);
        const idx = findWithAttr(state.list, 'id', story.id);
        
        if (story && idx > -1) {
            story.is_published = isPublished;
            Vue.set(state.list, idx, story);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
