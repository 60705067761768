<template>
    <div class="page prices-page">
        <div class="page__title">
            <nav-back/>
            Справочник цен
            <ui-button color="blue" class="page__add-button" @clicked="onAddClick">
                Добавить
            </ui-button>
        </div>

        <table class="users-list table table-striped">
            <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Валюта</th>
                <th scope="col">Описание</th>
                <th scope="col">Цена</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(price, i) of prices" :key="i">
                <th scope="row">{{ price.id }}</th>
                <td>{{ getCurrencyName(price.money.id) }}</td>
                <td style="max-width: 200px;">{{ price.description }}</td>
                <td>{{ price.money.value }}</td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil page__active-icon" viewBox="0 0 16 16"
                         @click="onEditClick(price)" v-b-tooltip.hover title="Редактировать"
                    >
                        <path
                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {openAddPriceModal} from '@/modals/addPriceModal';
import {openEditPriceModal} from '@/modals/editPriceModal';
import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('currencies', ['getCurrencyName']),
        prices() {
            return this.$store.state.prices.list;
        }
    },
    components: {
        NavBack: () => import('../components/navigation/NavBack'),
        UiButton: () => import('../components/ui/UiButton')
    },
    created() {
        this.fetchData();
    },
    methods: {
        ...mapActions('prices', ['getPrices']),
        ...mapActions('currencies', ['getCurrencies']),
        async fetchData() {
            await this.getCurrencies();
            await this.getPrices();
        },
        onAddClick() {
            openAddPriceModal();
        },
        onEditClick(price) {
            openEditPriceModal(price);
        },
    }
}
</script>
