<template>
    <div class="chapter-page page">
        <div class="chapter-page__header">
            <div class="chapter-page__header-left">
                <nav-back/>
                <div class="chapter-page__number">{{ name }}</div>
                <div class="chapter-page__title">{{ title }}</div>
            </div>
            <div class="chapter-page__header-right">
                <ui-button class="chapter-page__save-button" :color="saveButtonColor" @clicked="onSaveClick"
                           :class="{'chapter-page__save-button--disabled': !anyChanges}"
                >
                    {{ saveButtonText }}
                </ui-button>
            </div>
        </div>
        <div class="row mt-3 ml-1" style="position: relative; margin-bottom: -20px;" v-if="chapterId">
            <div class="chapter-page__story-id mr-2" v-b-tooltip.hover title="Скопировать ID истории"
                 @click="clip(storyId)"
            >
                ID истории: {{ storyId }}
            </div>
            <div class="chapter-page__story-id" v-b-tooltip.hover title="Скопировать ID главы"
                 @click="clip(chapterId)"
            >
                ID главы: {{ chapterId }}
            </div>
        </div>
        <div class="chapter-page__content d-flex mb-3">
            <div class="page__title row">Описание главы</div>
            <div class="row">
                <div class="chapter-page__image col">
                    <div class="chapter-page__image-title">Изображение главы</div>
                    <div v-if="coverImage" :style="{backgroundImage: `url(${coverImage})`}"
                         class="chapter-page__image-cover">
                        <img :src="require('../assets/svg/ic_refresh.svg')" width="24" height="24"
                             class="story-page__image-button" alt="" @click="onAddCoverImageClick"/>
                        <img :src="require('../assets/svg/ic_trash.svg')" width="24" height="24"
                             class="story-page__image-button" alt="" @click="onCoverImageDeleteClick"/>
                    </div>
                    <div v-else class="chapter-page__image-upload-block" @click="onAddCoverImageClick">
                        <div class="chapter-page__image-upload-plus-icon">
                            <div class="d-flex">+</div>
                        </div>
                    </div>
                    <input type="file" accept="image/png" @change="uploadCoverImage($event)"
                           style="display: none;" ref="coverImageUploadButton">
                </div>
                <div class="chapter-page__image col">
                    <div class="chapter-page__image-title">Подарочная карточка</div>
                    <div v-if="giftCardImage" :style="{backgroundImage: `url(${giftCardImage})`}"
                         class="chapter-page__image-cover">
                        <img :src="require('../assets/svg/ic_refresh.svg')" width="24" height="24"
                             class="story-page__image-button" alt="" @click="onAddGiftCardImageClick"/>
                        <img :src="require('../assets/svg/ic_trash.svg')" width="24" height="24"
                             class="story-page__image-button" alt="" @click="onGiftCardImageDeleteClick"/>
                    </div>
                    <div v-else class="chapter-page__image-upload-block" @click="onAddGiftCardImageClick">
                        <div class="chapter-page__image-upload-plus-icon">
                            <div class="d-flex">+</div>
                        </div>
                    </div>
                    <input type="file" accept="image/png" @change="uploadGiftImage($event)"
                           style="display: none;" ref="giftImageUploadButton">
                </div>
            </div>
            <div class="row page__input">
                <div class="form-group">
                    <label for="chapterTitleInput" class="page__input-title">Название</label>
                    <input type="text" class="form-control" id="chapterTitleInput"
                           placeholder="Название" v-model="title"
                           :class="{'data-changed': titleChanged}"
                    >
                </div>
            </div>
            <div class="row page__input">
                <div class="form-group">
                    <label class="page__input-title">Краткое описание</label>
                    <textarea class="form-control" id="chapterDescriptionInput" rows="3"
                              placeholder="Описание" v-model="description"
                              :class="{'data-changed': descriptionChanged}"
                    ></textarea>
                </div>
            </div>
            <div class="row page__input">
                <div class="form-group">
                    <label for="googleSheetId" class="page__input-title">ID Google таблицы</label>
                    <input type="text" class="form-control" id="googleSheetId"
                           placeholder="ID таблицы" v-model="googleSheetId"
                           :class="{'data-changed': googleSheetChanged}"
                    >
                </div>
            </div>
            <div class="row page__sub-row">
                <div class="col">
                    <div class="form-group">
                        <label class="page__input-title">Время чтения</label><br/>
                        <b-dropdown :text="readingTimeText"
                                    class="m-md-2 chapter-page__filter-dropdown page__input-small ml-4"
                                    :class="{'data-changed': readingTimeChanged}"
                        >
                            <b-dropdown-item v-for="(config, i) of readingTime" :key="i" :data-value="config.time"
                                             @click="onReadingTimeSelect"
                            >
                                {{ config.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>

            <div class="row page__sub-row">
                <div class="col">
                    <div class="form-group">
                        <label class="page__input-title">Стоимость чтения</label><br/>
                        <b-dropdown :text="readingCurrencyText" id="storyCharacterRestriction" class="m-md-2"
                                    :class="{'data-changed': readingRuleChanged}">
                            <b-dropdown-item v-for="currency of currenciesOptions" :key="currency.value"
                                             :data-value="currency.value"
                                             @click="onReadingCurrencyChanged"
                            >
                                {{ currency.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <input type="number" class="form-control mt-2" placeholder="0" v-model.number="readingCost"
                               :class="{'data-changed': readingRuleChanged}"
                               min=0 oninput="validity.valid||(value=0)"
                        />
                    </div>
                </div>
            </div>

            <label class="chapter-page__label" :class="{'data-changed': rewardsChanged}">
                Награды за прохождение главы
            </label>
            <div v-for="(reward) of rewards" :key="reward.currency_id" class="mb-4" @click="onRewardsClick"
                 v-if="typeof selectedRewardOptions[`currency-${reward.currency_id}`] !== undefined"
            >
                <div class="d-flex flex-row align-items-center">
                    <div class="d-flex flex-column">
                        <b-form-select v-model="selectedRewardOptions[`currency-${reward.currency_id}`]"
                                       :options="currenciesOptions"
                                       class="form-control chapter-page__reward-input"/>
                        <input type="number" placeholder="Количество"
                               class="form-control mt-2 chapter-page__reward-input"
                               v-model.number="rewardValues[`currency-${reward.currency_id}`]"
                               min=0 oninput="validity.valid||(value=0)"
                        >
                    </div>
                    <div class="d-flex flex-column">
                        <svg v-if="!isDefaultReward"
                             xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                             v-b-tooltip.hover title="Удалить награду" @click="deleteReward(reward.currency_id)"
                             class="bi bi-x-circle ml-4 story-page__reset-reward" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <span v-if="!rewards.length" class="mb-3 mt-1" style="font-size: 16px;">Отсутствуют</span>
            <ui-button color="transparent" @clicked="addReward" class="mb-2"
                       style="max-width: 290px;"
                       v-if="getAvailableCurrencies().length"
            >
                Добавить награду
            </ui-button>
            <ui-button color="transparent" @clicked="resetRewardToDefault"
                       style="max-width: 290px;"
            >
                Сбросить награды на дефолт
            </ui-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import bus from '@/plugins/bus';

function DuplicateRewardException(currencyId) {
    this.currencyId = currencyId;
}

const DEFAULT_READING_CURRENCY_ID = 2; // билетики
const DEFAULT_READING_PRICE = 0;

export default {
    props: {
        chapterId: Number,
        storyId: Number,
        chapterData: Object
    },
    data: () => ({
        id: 0,
        name: '',
        title: 'Новая глава',
        description: 'Описание главы',
        googleSheetId: '',
        readingTimeOption: 0,
        coverImage: '',
        giftCardImage: '',
        rewards: [],

        anyChanges: false,
        dataFetched: false,

        // data changes
        idChanged: false,
        titleChanged: false,
        descriptionChanged: false,
        readingTimeChanged: false,
        googleSheetChanged: false,
        rewardsChanged: false,

        currenciesOptions: [
            {
                value: 0,
                text: 'Ожидание'
            }
        ],
        selectedRewardOptions: {},
        rewardValues: {},

        isDefaultReward: true,

        readingCost: 1,
        readingCurrencyOption: 2,
        readingCurrencyText: '',
        readingRuleChanged: false
    }),
    components: {
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
    },
    computed: {
        ...mapState(['configs']),
        ...mapState('stories', ['storyStatuses', 'readingTime']),
        ...mapGetters('stories', ['getNextChapterOrder']),
        currencies() {
            return this.$store.state.currencies.list;
        },
        readingTimeText() {
            const config = this.readingTime.find(story => story.time === this.readingTimeOption);
            return config ? config.text : 'Ошибка';
        },
        realStoryId() {
            return this.storyId || 0;
        },
        saveButtonText() {
            return this.anyChanges ? 'Сохранить' : 'Сохранено';
        },
        saveButtonColor() {
            return this.anyChanges ? 'blue' : 'transparent';
        },
    },
    async mounted() {
        await this.fetchData();
    },
    watch: {
        id() {
            this.changesCommonHandler('id');
        },
        title() {
            this.changesCommonHandler('title');
        },
        description() {
            this.changesCommonHandler('description');
        },
        googleSheetId() {
            this.changesCommonHandler('googleSheet');
        },
        readingTimeOption() {
            this.changesCommonHandler('readingTime');
        },
        readingCurrencyOption() {
            this.changesCommonHandler('readingRule');
        },
        readingCost() {
            this.changesCommonHandler('readingRule');
        },
        coverImage(value, oldValue) {
            if (oldValue) {
                this.anyChanges = true;
            }
        },
        giftCardImage(value, oldValue) {
            if (oldValue) {
                this.anyChanges = true;
            }
        },
    },
    methods: {
        ...mapActions(['getConfigs']),
        ...mapActions('stories', ['getStories', 'createStoryChapter', 'updateStoryChapter', 'deleteStoryChapter']),
        ...mapActions('currencies', ['getCurrencies']),
        async fetchData() {
            await this.getCurrencies();
            await this.getStories();

            if (this.chapterData) {
                this.id = this.chapterData.id;
                this.title = this.chapterData.title;
                this.description = this.chapterData.description;
                this.readingTimeOption = this.chapterData.reading_time;
                this.coverImage = this.chapterData.url_image_big;
                this.giftCardImage = this.chapterData.url_image_small;
                this.name = this.chapterData.name;
                this.googleSheetId = this.chapterData.google_sheet_id;
                this.isDefaultReward = this.chapterData.reward_default;
                this.readingCurrencyOption = this.chapterData.reading_currency_id || DEFAULT_READING_CURRENCY_ID;
                this.readingCost = this.chapterData.reading_currency_value || DEFAULT_READING_PRICE;

                if (this.chapterData.rewards.length)
                    this.rewards = this.chapterData.rewards || [];
                else
                    this.setDefaultReward();
            } else {
                this.readingTimeOption = this.readingTime[0].time;
                this.readingCurrencyOption = DEFAULT_READING_CURRENCY_ID;
                this.readingCost = DEFAULT_READING_PRICE;
                this.setDefaultReward();
            }

            setTimeout(() => {
                this.dataFetched = true;
            }, 250);

            this.fillSelects();
        },
        addReward() {
            const availableCurrencies = this.getAvailableCurrencies();

            if (!availableCurrencies.length)
                return;

            this.rewards.push({
                currency_id: availableCurrencies[0].id,
                value: 0
            });
            this.selectedRewardOptions[`currency-${availableCurrencies[0].id}`] = availableCurrencies[0].id;
            this.rewardValues[`currency-${availableCurrencies[0].id}`] = 0;

            this.isDefaultReward = false;
        },
        deleteReward(currencyId) {
            this.rewards = this.rewards.filter(r => r.currency_id !== currencyId);
            delete this.selectedRewardOptions[`currency-${currencyId}`];
            delete this.rewardValues[`currency-${currencyId}`];
            !this.rewards.length && this.isDefaultReward && this.setDefaultReward();
        },
        setDefaultReward() {
            this.rewards = [{
                currency_id: this.configs.chapter_reward_currency_id,
                value: this.configs.chapter_reward_currency_value,
            }];
            this.selectedRewardOptions = {
                [`currency-${this.configs.chapter_reward_currency_id}`]: this.configs.chapter_reward_currency_id
            };
            this.rewardValues = {
                [`currency-${this.configs.chapter_reward_currency_id}`]: this.configs.chapter_reward_currency_value
            };
            this.isDefaultReward = true;
        },
        fillSelects() {
            this.currenciesOptions = this.currencies
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });

            const readingCurrencyData = this.currenciesOptions.find(c => c.value == this.readingCurrencyOption);
            this.readingCurrencyOption = readingCurrencyData.value;
            this.readingCurrencyText = readingCurrencyData.text;

            if (this.rewards.length && !this.isDefaultReward) {
                this.rewards.forEach((reward) => {
                    this.selectedRewardOptions[`currency-${reward.currency_id}`] = reward.currency_id;
                    this.rewardValues[`currency-${reward.currency_id}`] = reward.value;
                });
            } else if (this.isDefaultReward) {
                this.rewards = [{
                    currency_id: this.configs.chapter_reward_currency_id,
                    value: this.configs.story_reward_currency_value
                }];
                this.selectedRewardOptions[`currency-${this.configs.chapter_reward_currency_id}`] = this.configs.chapter_reward_currency_id;
                this.rewardValues[`currency-${this.configs.chapter_reward_currency_id}`] = this.configs.chapter_reward_currency_value;
            }
        },
        changesCommonHandler(fieldName) {
            if (!this.dataFetched) {
                return;
            }

            this.anyChanges = true;

            this[`${fieldName}Changed`] = true;
        },
        onReadingTimeSelect(event) {
            this.readingTimeOption = parseInt(event.currentTarget.dataset.value, 10);
        },
        async onDeleteClick() {
            await this.deleteStoryChapter({
                storyId: this.realStoryId,
                chapterId: this.realChapterData.id
            });
            await this.$router.push(`/story/${this.realStoryId}`);
        },
        async onSaveClick() {
            this.anyChanges = false;

            const chapterData = {};

            if (this.chapterData && this.chapterData.id) {
                chapterData.id = this.chapterData.id;
            } else {
                chapterData.is_published = false;
                chapterData.order = this.getNextChapterOrder(this.storyId);
            }

            if (this.coverImage.includes(';base64,')) {
                chapterData.image_big = this.coverImage.replace(/^data:.+;base64,/, '');
            } else if (!this.coverImage.includes('https://')) {
                alert('Необходимо загрузить изображение главы');
                return;
            }

            if (this.giftCardImage.indexOf(';base64,') > -1) {
                chapterData.image_small = this.giftCardImage.replace(/^data:.+;base64,/, '');
            } else if (!this.giftCardImage.includes('https://')) {
                alert('Необходимо загрузить подарочную карточку');
                return;
            }

            if (this.googleSheetId.length > 0) {
                chapterData.google_sheet_id = this.googleSheetId;
            } else {
                alert('Необходимо заполнить ID google таблицы для главы');
                return;
            }

            chapterData.title = this.title;
            chapterData.description = this.description;
            chapterData.reading_time = parseInt(this.readingTimeOption, 10);
            chapterData.reading_currency_id = this.readingCurrencyOption;
            chapterData.reading_currency_value = parseInt(this.readingCost, 10);

            if (this.rewardsChanged) {
                let rewardsCounter = {};

                chapterData.rewards = [];

                Object.entries(this.selectedRewardOptions).forEach(([key, currencyId]) => {
                    chapterData.rewards.push({
                        currency_id: currencyId,
                        value: parseInt(this.rewardValues[key], 10)
                    });

                    if (!rewardsCounter[currencyId])
                        rewardsCounter[currencyId] = 1;
                    else
                        rewardsCounter[currencyId]++;
                });

                try {
                    Object.entries(rewardsCounter).forEach(([currencyId, count]) => {
                        if (count > 1)
                            throw new DuplicateRewardException(parseInt(currencyId), 10);
                    });
                } catch (e) {
                    if (!e.currencyId) {
                        throw e;
                    } else {
                        const currency = this.currencies.find(c => c.id === e.currencyId);
                        bus.$emit('show-notification-message', `Дублирующаяся награда: ${currency.name}`);
                        return;
                    }
                }

                chapterData.reward_default = this.isDefaultReward = false;
            } else if (!chapterData.id) {
                chapterData.rewards = [];
                chapterData.reward_default = true;
            }

            if (!chapterData.id) {
                const res = await this.createStoryChapter({
                    storyId: parseInt(this.realStoryId, 10),
                    chapterData
                });

                if (!res.error) {
                    bus.$emit('show-notification-message', 'Сохранено');
                    this.resetChangedFlags();

                    await this.$router.push({
                        name: 'chapters',
                        params: {
                            chapterId: res.id,
                            storyId: parseInt(res.story_id, 10),
                            chapterData: res
                        },
                        hash: `/${res.id}`
                    });
                } else {
                    bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
                }
            } else {
                const res = await this.updateStoryChapter({
                    storyId: parseInt(this.realStoryId, 10),
                    chapterData
                });

                if (!res.error) {
                    bus.$emit('show-notification-message', 'Сохранено');
                    this.resetChangedFlags();
                } else {
                    bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
                }
            }
        },
        async resetRewardToDefault() {
            const res = await this.updateStoryChapter({
                storyId: parseInt(this.realStoryId, 10),
                chapterData: {
                    id: this.chapterData.id,
                    rewards: [],
                    reward_default: true
                }
            });

            if (!res.error) {
                bus.$emit('show-notification-message', 'Награда сброшена на дефолт');

                this.setDefaultReward();
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },

        onAddCoverImageClick() {
            if (!this.$refs.coverImageUploadButton) return;
            this.$refs.coverImageUploadButton.click();
        },
        uploadCoverImage(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = event => {
                this.coverImage = event.target.result;
                this.anyChanges = true;
            };
        },
        onGiftCardImageDeleteClick() {
            this.giftCardImage = '';
        },

        onAddGiftCardImageClick() {
            if (!this.$refs.giftImageUploadButton) return;
            this.$refs.giftImageUploadButton.click();
        },
        uploadGiftImage(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = event => {
                this.giftCardImage = event.target.result;
                this.anyChanges = true;
            };
        },
        onCoverImageDeleteClick() {
            this.coverImage = '';
        },
        resetChangedFlags() {
            this.idChanged = false;
            this.titleChanged = false;
            this.descriptionChanged = false;
            this.readingTimeChanged = false;
            this.rewardsChanged = false;
            this.readingRuleChanged = false;
        },
        getAvailableCurrencies() {
            const usedCurrencies = Object.keys(this.selectedRewardOptions)
                .map(c => parseInt(c.split('currency-')[1], 10));
            return this.currencies.filter(c => usedCurrencies.indexOf(c.id) === -1 && c.id > 0);
        },
        onRewardsClick() {
            this.anyChanges = true;
            this.rewardsChanged = true;
        },
        clipStoryId() {
            this.$clipboard(this.storyId);
            bus.$emit('show-notification-message', 'ID скопирован в буфер обмена');
        },
        clip(id) {
            this.$clipboard(id);
            bus.$emit('show-notification-message', 'ID скопирован в буфер обмена');
        },
        onReadingCurrencyChanged(event) {
            this.readingCurrencyOption = parseInt(event.currentTarget.dataset.value, 10);
            this.readingCurrencyText = this.currenciesOptions.find(c => c.value == this.readingCurrencyOption).text;
        }
    }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";

.chapter-page {
    &__story-id,
    &__chapter-id {
        font-size: 16px;
        user-select: text;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 700;
    }

    &__header {
        display: flex;
        text-align: left;
        float: left;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: 20px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            background: #9AAABC;
            width: 200vw;
            height: 1px;
            bottom: 0;
            left: -100vw;
        }
    }

    &__header-left {
        display: flex;
        align-items: center;
    }

    &__header-right {
        display: flex;
        align-items: center;
    }

    &__number {
        font-weight: 700;
        font-size: 32px;
        margin-right: 32px;
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        margin-top: 6px;
    }

    &__save-button {
        margin-left: 24px;

        &--disabled {
            pointer-events: none;
        }
    }

    &__content {
        padding-top: 16px;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .page-title {
            margin-bottom: 32px !important;
        }

        .row,
        .col {
            margin: 0;
            padding: 0;
        }

        .col {
            max-width: 288px;
        }
    }

    &__image-upload-block {
        background: #C3DFFF;
        width: 272px;
        height: 160px;
        border-radius: 16px;
        position: relative;
        display: flex;
        justify-self: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
    }

    &__image-upload-plus-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $primaryColor;
        color: #fff;
        position: absolute;
        bottom: 16px;
        right: 16px;
        display: flex;
        font-size: 24px;
        align-items: center;
        justify-content: center;
    }

    &__image-title {
        margin-bottom: 16px;
        font-size: 16px;
    }

    &__image-cover {
        width: 272px;
        height: 160px;
        background-size: cover;
        border-radius: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 8px;
    }

    .row {
        margin-bottom: 16px;
    }

    &__delete-button {
        text-transform: uppercase;
        font-size: 14px;
        max-width: 232px;
        margin-bottom: 154px;
    }

    &__status-dropdown {
        button {
            padding-right: 24px;
        }
    }

    &__label {
        box-shadow: none !important;
        font-size: 16px;
    }

    &__reward-input {
        max-width: 270px;
    }
}
</style>
