import Popup from "@/lib/Popup";
import SetUserChapterModal from '@/components/modals/SetUserChapterModal';

export const openSetUserChapterModal = (playerId, progressData) => {
    const popup = new Popup({
        component: SetUserChapterModal,
        props: {playerId, progressData},
        params: {
            transition: 'scale',
            name: 'set-user-chapter-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openSetUserChapterModal};
