import store from '@/store';
import {getCookie} from '@/utils';
import bus from '@/plugins/bus';

const axios = require('axios');
const withCredentials = true;
const AdminPrefix = '/api/admin';

const checkAuthError = status => {
    if (status === 401) {
        bus.$emit('show-notification-message', 'Ошибка авторизации');
        app.$router.push('/login');
    }
};

class Api {
    constructor() {
        this.API_URL = process.env.VUE_APP_API_URL;
    }
    
    getApiUrl() {
        let apiUrl = getCookie('api_url');
    
        if (!apiUrl) {
            apiUrl = window.api_url || this.API_URL;
        }
    
        return apiUrl;
    }
    
    fetch(url, params) {
        const config = {
            withCredentials,
            crossdomain: true,
        };
    
        if (store.state.currentUser.token) {
            config.headers = { Authorization: `Bearer ${store.state.currentUser.token}` };
        }
        
        this.API_URL = this.getApiUrl();
        
        return new Promise((resolve, reject) => {
            axios.get(`${AdminPrefix}${url}`, {
                ...config,
                params,
            })
                .then((response) => {
                    const {data} = response;
                    console.log('API SUCCESS RESPONSE:');
                    console.log(data);
                    resolve(data);
                })
                .catch(error => {
                    console.log('API ERROR');
                    console.log(error);
                    checkAuthError(error.response.status);
                    reject(error);
                });
        });
    }
    
    post(url, params) {
        const config = {
            withCredentials,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    
        if (store.state.currentUser.token) {
            config.headers.Authorization = `Bearer ${store.state.currentUser.token}`;
        }
        
        this.API_URL = this.getApiUrl();
        
        return new Promise((resolve, reject) => {
            axios.post(`${AdminPrefix}${url}`, params, config)
                .then((response) => {
                    const {data} = response;
                    console.log('API SUCCESS RESPONSE:');
                    console.log(data);
                    resolve(data);
                })
                .catch(error => {
                    console.log('API ERROR');
                    console.log(error);
                    checkAuthError(error.response.status);
                    reject(error);
                });
        });
    }
    
    patch(url, params) {
        const config = {
            withCredentials,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    
        if (store.state.currentUser.token) {
            config.headers.Authorization = `Bearer ${store.state.currentUser.token}`;
        }
        
        this.API_URL = this.getApiUrl();
        
        return new Promise((resolve, reject) => {
            axios.patch(`${AdminPrefix}${url}`, params, config)
                .then((response) => {
                    const {data} = response;
                    console.log('API SUCCESS RESPONSE:');
                    console.log(data);
                    resolve(data);
                })
                .catch(error => {
                    console.log('API ERROR');
                    console.log(error);
                    checkAuthError(error.response.status);
                    reject(error);
                });
        });
    }
    
    put(url, params) {
        const config = {
            withCredentials,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    
        if (store.state.currentUser.token) {
            config.headers.Authorization = `Bearer ${store.state.currentUser.token}`;
        }
        
        this.API_URL = this.getApiUrl();
        
        return new Promise((resolve, reject) => {
            axios.put(`${AdminPrefix}${url}`, params, config)
                .then((response) => {
                    const {data} = response;
                    console.log('API SUCCESS RESPONSE:');
                    console.log(data);
                    resolve(data);
                })
                .catch(error => {
                    console.log('API ERROR');
                    console.log(error);
                    checkAuthError(error.response.status);
                    reject(error);
                });
        });
    }
    
    delete(url, params) {
        const config = {
            withCredentials,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    
        if (store.state.currentUser.token) {
            config.headers.Authorization = `Bearer ${store.state.currentUser.token}`;
        }
    
        this.API_URL = this.getApiUrl();
    
        return new Promise((resolve, reject) => {
            axios.delete(`${AdminPrefix}${url}`, config)
                .then((response) => {
                    const {data} = response;
                    console.log('API SUCCESS RESPONSE:');
                    console.log(data);
                    resolve(data);
                })
                .catch(error => {
                    console.log('API ERROR');
                    console.log(error);
                    checkAuthError(error.response.status);
                    reject(error);
                });
        });
    }
}

const api = new Api();

export default api;
