<template>
    <div class="page products-page">
        <div class="page__title">
            <nav-back/>
            Список товаров
            <ui-button style="position: absolute;right: 0;" @clicked="onSortingButtonClick">Сортировка</ui-button>
        </div>
        <h1>Мульти-паки</h1>
        <multi-packs-list v-if="ready" v-bind:packs="multiPacksList"/>
        <h1>Одиночные паки</h1>
        <packs-list v-if="ready" v-for="currencyId in Object.keys(singlePacksList)" :key="currencyId"
                    v-bind="currency(currencyId)"
                    v-bind:packs="singlePacksList[currencyId]"/>
        <template v-if="!Object.keys(singlePacksList).length">
            <ui-button class="products-page__add-single-pack-button" @clicked="onAddSinglePackClick">
                Добавить товар
            </ui-button>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {openProductsSortingModal} from '@/modals/productsSortingModal';
import {openMultiPackModal} from '@/modals/addMultiPackModal';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        ready: false
    }),
    components: {
        NavBack: () => import('../components/navigation/NavBack'),
        UiButton: () => import('../components/ui/UiButton'),
        PacksList: () => import('../components/products/PacksList'),
        MultiPacksList: () => import('../components/products/MultiPacksList'),
    },
    computed: {
        ...mapGetters('currencies', ['currency']),
        ...mapGetters('products', ['multiProductsList', 'singleProductsList']),
        currencies() {
            return this.$store.state.currencies.list.filter(c => c.id !== 0);
        },
        singlePacksList() {
            return this.singleProductsList;
        },
        multiPacksList() {
            return this.multiProductsList;
        }
    },
    async created() {
        bus.$off('products-list-updated').$on('products-list-updated', this.onProductsUpdated);
        await this.getCurrencies();
        await this.getProducts();
        this.ready = true;
    },
    beforeDestroy() {
        bus.$off('products-list-updated', this.onProductsUpdated);
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('products', ['getProducts']),
        ...mapMutations('products', ['UPDATE_PRODUCT']),
        onSortingButtonClick() {
            openProductsSortingModal();
        },
        onProductsUpdated() {
            this.$forceUpdate();
        },
        onAddSinglePackClick() {
            openMultiPackModal();
        }
    },
};
</script>

<style lang="scss">
.products-page {
    svg {
        cursor: pointer;
    }

    &__add-single-pack-button {
        max-width: 200px;
    }
}
</style>
