<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onAddUserClick">
        <div class="mb-3 modal-popup__title">
            Добавление пользователя

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <input type="email" class="form-control mb-2" placeholder="Email" v-model="email"
               :class="[validationClass()]" ref="input"
        >

        <input type="password" class="form-control mb-2" placeholder="Пароль" v-model="password">
        <input type="password" class="form-control mb-2" placeholder="Подтверждение пароля"
               v-model="confirmPassword">

        <div class="modal-popup__permissions">
            <label for="permissionSelect" class="modal-popup__permission-text">Права доступа</label>
            <select :size="permissions.length + 1" class="form-select" multiple
                    aria-label="multiple select" ref="permissionSelect" id="permissionSelect">
                <option v-for="(permission, id) of permissions" :key="id" :value="permission.id">
                    {{ permission.name }}
                </option>
            </select>
        </div>
        <ui-button class="mt-3 mb-2" @clicked="onAddUserClick" :disabled="!isValidData()">Добавить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {validateEmail} from '@/utils';
import {mapActions, mapState} from 'vuex';

export default {
    data: () => ({
        email: '',
        password: '',
        confirmPassword: '',
        alert: '',
        canReset: false
    }),
    computed: {
        permissions() {
            return this.$store.state.permissions.list;
        }
    },
    mounted() {
        this.$refs.input.focus();
    },
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    methods: {
        ...mapActions('users', ['addUser']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        validationClass() {
            return (this.email === '') ? '' : (validateEmail(this.email)) ? 'email-valid' : 'email-invalid';
        },
        isValidData() {
            if (!validateEmail(this.email)) {
                this.setAlert('Введен некорректный email');
                return false;
            }

            if (this.password === '') {
                this.setAlert('Необходимо ввести пароль');
                return false;
            }

            if (this.password !== this.confirmPassword) {
                this.setAlert('Пароли должны совпадать');
                return false;
            }

            let permissions = [];

            this.$refs.permissionSelect.forEach(option => {
                if (option.selected) {
                    permissions.push(parseInt(option.value, 10));
                }
            });

            if (!permissions.length) {
                this.setAlert('Необходимо выбрать права доступа');
                return false;
            }

            this.alert = '';

            return true;
        },
        async onAddUserClick() {
            if (!this.isValidData()) return false;

            let permissions = [];

            this.$refs.permissionSelect.forEach(option => {
                if (option.selected) {
                    permissions.push(parseInt(option.value, 10));
                }
            });

            const res = await this.addUser({
                email: this.email,
                password: this.password,
                permissions
            });

            if (res.error) {
                console.log('Ошибка сохранения пользователя');
                console.log(res.error.response.data);

                if (res.error.response.data.email) {
                    this.setAlert(res.error.response.data.email[0]);
                } else {
                    this.setAlert(`Ошибка: ${res.error.toString()}`);
                }
            } else {
                this.close();
            }
        },
        close() {
            this.$modal.hide('add-user-modal')
        }
    }
};
</script>
