<template>
    <div class="nav-bar-top">
        <div class="nav-bar-top__logged-block" :class="{'nav-bar-top__logged-block--hidden': !token}">
            <div class="nav-bar-top__menu" @click="onMenuClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#2D4C70" class="bi bi-list"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </div>
        </div>
        <div class="container nav-bar-top__logo-wrapper">
            <div class="nav-bar-top__logo" @click="onLogoClick">NOVEL ADMIN</div>
        </div>
        <div v-if="token" class="nav-bar-top__login">
            <div class="nav-bar-top__login-name">{{ email || 'Вход' }}</div>
            <div class="nav-bar-top__logout-button" @click="onLogoutClick"/>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import bus from '@/plugins/bus';

export default {
    computed: {
        ...mapState('currentUser', ['email', 'token'])
    },
    methods: {
        ...mapMutations('currentUser', ['UPDATE']),
        onLogoClick() {
            this.$router.push('/');
        },
        onLogoutClick() {
            this.UPDATE({
                id: 0,
                token: null,
                permissions: [],
                email: ''
            });
            this.$router.push('/login');
        },
        onMenuClick() {
            bus.$emit('menu-clicked');
        }
    }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.nav-bar-top {
    width: 100vw;
    height: 64px;
    background: #DDEDFF;
    position: relative;

    &__logged-block {
        position: absolute;
        top: 0;
        left: 0;

        &--hidden {
            visibility: hidden;
        }
    }

    &__menu {
        width: 72px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__logo-wrapper {
        position: absolute;
        transform: translateX(-50%);
        margin-left: 50%;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #2D4C70;
        font-size: 18px;
        font-weight: 700;
        width: 200px;
        height: 64px;
        cursor: pointer;
    }

    &__login {
        position: absolute;
        right: 56px;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__login-name {
        color: #4F6D90;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        text-align: left;
    }

    &__logout-button {
        cursor: pointer;
        background: url(../../assets/svg/ic_logout.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
        margin-left: 16px;
    }
}
</style>
