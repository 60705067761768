<template>
    <div class="modal-popup">
        <div class="mb-3 modal-popup__title">
            Добавление пака

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <div class="input-group mb-4">
            <label class="modal-popup__label text-left" style="width: 100%;">Иконка пака</label>
            <img v-if="image.length > 0" :src="image" alt=""/>
            <input v-else type="file" accept="image/png" @change="loadImage($event)" ref="imageUploadButton"
                   class="d-flex"
            />
        </div>
        <div class="input-group">
            <label class="modal-popup__label text-left" style="width: 100%;">Google ID</label>
            <input type="text" v-model="googleID" class="form-control"/>
        </div>
        <div class="input-group mb-3">
            <label class="modal-popup__label text-left" style="width: 100%;">Apple ID</label>
            <input type="text" v-model="appleID" class="form-control"/>
        </div>
        <div class="input-group mb-3">
            <label class="modal-popup__label text-left" style="width: 100%;">Бонус первой покупки %</label>
            <input type="number" v-model.number="firstBonus" class="form-control"
                   min=0 oninput="validity.valid||(value=0)"/>
        </div>
        <div class="input-group mb-3 align-items-center" v-for="(_, i) of rewards">
            <b-form-select class="form-control" v-model="selectedCurrencies[i]"
                           :options="currenciesOptions"/>
            <input type="number" class="form-control" v-model.number="rewards[i].value"
                   min=1 oninput="validity.valid||(value=0)"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                 class="bi bi-trash ms-3 ml-2" viewBox="0 0 16 16" v-b-tooltip.hover title="Удалить"
                 style="cursor: pointer;"
                 @click="onDeleteRewardClick(i)"
            >
                <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
        </div>
        <div class="d-flex justify-content-center" style="width: 100%;">
            <ui-button class="mt-3 mb-2 d-flex" style="width: 200px;" @clicked="onAddRewardClick">
                Добавить валюту
            </ui-button>
        </div>
        <div class="d-flex justify-content-center" style="width: 100%;">
            <ui-button class="mt-3 mb-2" style="width: 200px;" @clicked="onSaveClick">Сохранить</ui-button>
        </div>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import {uploadImage} from '@/utils';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        alert: '',
        canReset: false,

        image: '',
        currenciesOptions: [],
        selectedCurrencies: [],
        rewards: [],
        googleID: '',
        appleID: '',
        firstBonus: 0
    }),
    computed: {
        currencies() {
            return this.$store.state.currencies.list;
        }
    },
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    async mounted() {
        await this.getCurrencies();
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('products', ['addProduct']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        fillSelects() {
            this.currenciesOptions = this.$store.state.currencies.list
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });
        },
        async onSaveClick() {
            if (!this.image.length) {
                this.setAlert('Необходимо загрузить иконку товара');
                return;
            }

            if (!this.googleID.length) {
                this.setAlert('Необходимо указать Google ID товара');
                return;
            }

            if (!this.appleID.length) {
                this.setAlert('Необходимо указать Apple ID товара');
                return;
            }

            if (!this.rewards.length) {
                this.setAlert('Необходимо задать хотя бы 1 валюту');
                return;
            }

            const currenciesEntries = {};
            const rewards = [];
            for (let i = 0; i < this.rewards.length; i++) {
                if (!this.rewards[i].value) {
                    this.setAlert('Не должно быть нулевых значений валют');
                    return;
                }

                if (currenciesEntries[this.selectedCurrencies[i]]) {
                    const currencyConfig = this.currencies.find(c => c.id === this.selectedCurrencies[i]);
                    this.setAlert(`Найдены дубликаты наград: ${currencyConfig.name}`);
                    return;
                } else
                    currenciesEntries[this.selectedCurrencies[i]] = 1;

                rewards.push({
                    id: this.selectedCurrencies[i],
                    value: parseInt(this.rewards[i].value, 10)
                });
            }

            const res = await this.addProduct({
                google_id: this.googleID,
                apple_id: this.appleID,
                image: this.image.replace(/^data:.+;base64,/, ''),
                first_bonus: this.firstBonus,
                rewards
            });

            if (res.error)
                this.setAlert('Ошибка добавления');
            else {
                if (res.rewards.length > 1)
                    bus.$emit('products-list-updated', res);
                this.close();
            }
        },
        onAddRewardClick() {
            this.rewards.push({
                id: 1,
                value: 1
            });
            this.selectedCurrencies.push(1);
            this.fillSelects();
        },
        close() {
            this.$modal.hide('add-multi-pack-modal');
        },
        loadImage(event) {
            uploadImage(event, result => this.image = result);
        },
        onDeleteRewardClick(index) {
            this.rewards.splice(index, 1);
            this.selectedCurrencies.splice(index, 1);
            this.fillSelects();
        }
    }
};
</script>

<style lang="scss">
.v--modal-overlay[data-modal="add-multi-pack-modal"] {
    position: absolute;
    height: auto;

    .modal-popup__overlay {
        min-height: 100vh !important;
        padding-bottom: 30vh;
        height: auto !important;
    }
}
</style>
