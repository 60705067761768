<template>
    <div id="app" @click="onAppClick">
        <nav-bar-top/>
        <nav-bar-left v-if="token"/>
        <div class="container">
            <router-view/>
        </div>
        <modals-container/>
        <notification-message/>
    </div>
</template>

<script>
import {mapState} from "vuex";
import bus from '@/plugins/bus';

export default {
    components: {
        NavBarTop: () => import('./components/navigation/NavBarTop'),
        NavBarLeft: () => import('./components/navigation/NavBarLeft'),
        NotificationMessage: () => import('./components/ui/NotificationMessage')
    },
    computed: {
        ...mapState('currentUser', ['token'])
    },
    methods: {
        onAppClick() {
            bus.$emit('app-clicked');
        }
    }
};
</script>
<style lang="scss">
@import "assets/scss/index";
</style>
