<template>
    <div class="modal-popup">
        <div class="mb-3 modal-popup__title">
            Выберите историю

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <div class="form-group">
            <b-form-select v-model="selectedStoryId" :options="options" :disabled="selectedStoryId === -1"/>
        </div>
        <ui-button @clicked="onAddClick">Добавить</ui-button>
    </div>
</template>

<script>
import bus from '@/plugins/bus';
import {mapActions} from 'vuex';

export default {
    props: {
        categoryId: Number
    },
    data: () => ({
        selectedStoryId: null,
        options: []
    }),
    components: {
        UiButton: () => import('../ui/UiButton')
    },
    computed: {
        stories() {
            return this.$store.state.stories.list
                .filter(story => !story.categories.find(category => category.id === this.categoryId));
        }
    },
    created() {
        this.options = this.stories
            .filter(story => {
                if (!story.categories) {
                    return true;
                } else {
                    return story.categories.indexOf(this.categoryId) === -1;
                }
            })
            .map(story => {
                return {
                    value: story.id,
                    text: story.title
                };
            });

        if (this.options.length) {
            this.selectedStoryId = this.options[0].value;
        } else {
            this.selectedStoryId = -1;
            this.options = [{
                value: -1,
                text: 'Нет подходящих историй'
            }];
        }
    },
    methods: {
        ...mapActions('stories', ['addStoryToCategory']),
        close() {
            this.$modal.hide('pick-story-modal');
        },
        async onAddClick() {
            const res = await this.addStoryToCategory({
                categoryId: this.categoryId,
                storyId: parseInt(this.selectedStoryId, 10)
            });

            if (!res.error) {
                bus.$emit('stories-updated');
                this.close();
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        }
    }
};
</script>
