const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export const validateEmail = email => emailReg.test(email);

export const clone = origin => JSON.parse(JSON.stringify(origin));

export const areEqual = (objA, objB) => JSON.stringify(objA) === JSON.stringify(objB);

export const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        ...options
    };
    
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    
    document.cookie = updatedCookie;
};

export const sortByAttrAsc = (arr, attrName) => {
    return arr.sort((a, b) => {
        if (a[attrName] < b[attrName]) {
            return -1;
        }
    
        if (a[attrName] > b[attrName]) {
            return 1;
        }
    
        return 0;
    });
};

export const sortByAttrDesc = (arr, attrName) => {
    return arr.sort((a, b) => {
        if (a[attrName] > b[attrName]) {
            return -1;
        }
    
        if (a[attrName] < b[attrName]) {
            return 1;
        }
    
        return 0;
    });
};

/**
 *
 * @param array
 * @param attr
 * @param value
 * @returns {number}
 */
export const findWithAttr = (array, attr, value) => {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};

export const toDataURL = (url, callback = () => {}) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const uploadImage = (event, callback = () => {}) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = event => callback(event.target.result);
};
