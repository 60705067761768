import Vue from 'vue';
import store from '@/store';

import {UPDATE, update} from '../utils';
import api from '@/api';
import apiMock from '@/api/mock';

const state = {
    list: {}
};

const getters = {};

const actions = {
    update,
    
    async getUsers({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/users').then(usersArr => {
                let list = {};
                for (let i = 0; i < usersArr.length; i++) {
                    list[usersArr[i].id] = usersArr[i];
                }
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addUser({commit}, userData) {
        return new Promise(resolve => {
            api.post('/users', userData).then(userData => {
                commit('ADD_USER', userData);
                resolve(userData);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async updateUser({state, commit}, userData) {
        return new Promise(resolve => {
            api.patch(`/users/${userData.id}`, userData).then(user => {
                // чекаем данные текущего текущего пользователя
                if (user.id === store.state.currentUser.id) {
                    commit('currentUser/UPDATE', user);
                }
                
                // обновляем данные в списке пользователей
                commit('UPDATE_USER', user);
                
                resolve(user);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async deleteUser({commit}, userId) {
        return new Promise(resolve => {
            apiMock.delete(`/users/${userId}`).then(_ => {
                commit('DELETE_USER', userId);
                resolve({success: 1});
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    ADD_USER(state, user) {
        Vue.set(state.list, user.id, user);
    },
    UPDATE_USER(state, user) {
        Vue.set(state.list, user.id, user);
    },
    DELETE_USER(state, userId) {
        Vue.delete(state.list, userId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
