<template>
    <div class="page daily-bonus-page">
        <div class="page__title">
            <nav-back/>
            Ежедневный бонус
            <ui-button color="blue" class="page__title-button" @clicked="onSaveClick" :disabled="!anyChanges">
                Сохранить
            </ui-button>
        </div>
        <template v-if="pageIsReady">
            <div class="daily-bonus-page__status">
                <label class="my-0">Статус:</label>
                <b-dropdown :text="statusText">
                    <b-dropdown-item v-for="(status, i) of statusOptions" :key="i" @click="onStatusSelect"
                                     :data-value="status.value">
                        {{ status.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div  class="daily-bonus-page__items-list">
                <draggable
                    class="list-group"
                    tag="ul"
                    v-model="items"
                    v-bind="dragOptions"
                    @start="dragging = true"
                    @end="dragging = false"
                    @change="onOrderChanged"
                    :move="onItemMoved"
                >
                    <transition-group type="transition" name="flip-list" class="d-flex flex-wrap">
                        <li
                            class="list-group-item daily-bonus-page__item"
                            v-for="item of items"
                            :key="item.id"
                        >
                            <daily-bonus-item v-bind:initial-data="item" v-bind:currencies="$store.state.currencies.list"
                                              ref="dailyBonusItems"
                                              @changed="onItemSettingsChanged"
                                              @deleted="onDeleteItem"
                            />
                        </li>
                    </transition-group>
                </draggable>
                <ui-button color="blue" class="float-left mt-3" @clicked="onAddDayClick">
                    Добавить день
                </ui-button>
            </div>
        </template>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        pageIsReady: false,
        anyChanges: false,
        items: [],
        dragging: false,
        dragLocked: false,

        statusText: '',
        statusOption: 1,
        statusOptions: [
            {value: 1, name: 'Включено'},
            {value: 0, name: 'Выключено'},
        ]
    }),
    components: {
        draggable,
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
        DailyBonusItem: () => import('../components/dailyBonus/DailyBonusItem')
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: "ghost",
                direction: 'vertical'
            };
        },
    },
    async created() {
        await this.getCurrencies();
        await this.getDailyBonusConfig();

        this.statusOption = this.$store.state.dailyBonus.config.is_enable ? 1 : 0;
        this.statusText = this.statusOptions.find(status => status.value === this.statusOption).name;
        this.items = this.$store.state.dailyBonus.config.bonuses;
        this.pageIsReady = true;
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('dailyBonus', ['getDailyBonusConfig', 'setDailyBonusEnabled', 'saveDailyBonusConfig']),
        onOrderChanged() {
            this.items.forEach((item, i) => {
                item.id = i + 1;
                this.$refs.dailyBonusItems[i].setData(item);
            });
            this.anyChanges = true;
        },
        onItemMoved() {
            return !this.dragLocked;
        },
        onItemSettingsChanged(id, property, value) {
            const item = this.items.find(item => item.id === id);
            if (!item || !item.hasOwnProperty(property)) return;
            item[property] = value;
            this.anyChanges = true;
        },
        async onSaveClick() {
            this.anyChanges = false;

            const config = [];
            for (let i = 0; i < this.$refs.dailyBonusItems.length; i++)
                config.push(this.$refs.dailyBonusItems[i].getData());

            const res = await this.saveDailyBonusConfig(config);

            if (!res.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        onAddDayClick() {
            this.items.push({
                id: this.items.length + 1,
                currency_id: 1,
                value: 0,
                vip_value: 0,
                ads_value: 0
            });
            this.anyChanges = true;
        },
        onDeleteItem(day) {
            if (!confirm(`Вы точно хотите удалить День #${day}?`)) return;
            this.items.splice(day - 1, 1);
            this.items.forEach((item, i) => {
                item.id = i + 1;
                this.$refs.dailyBonusItems[i].setData(item);
            });
            this.anyChanges = true;
        },
        async onStatusSelect(event) {
            this.statusOption = parseInt(event.target.dataset.value, 10);

            const res = await this.setDailyBonusEnabled(this.statusOption === 1);

            if (!res.error) {
                this.statusText = this.statusOptions.find(status => status.value === this.statusOption).name;
                bus.$emit('show-notification-message', 'Статус обновлен');
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        }
    }
};
</script>

<style lang="scss">
.daily-bonus-page {
    &__items-list {
        .list-group {
            flex-direction: column;
        }
    }

    &__item {
        width: 100%;
        border: 1px solid rgb(84 84 84 / 22%) !important;
        border-radius: 8px !important;
        margin: 4px 0;
    }

    &__status {
        display: grid;
        grid-template-columns: 72px 100px;
        align-items: center;
        margin-bottom: 8px;
        text-align: left;
    }
}
</style>
