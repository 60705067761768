import PERMISSIONS from '@/configs/permissions';
import bus from '@/plugins/bus';

export default async function hasPermissions({to, next, store}) {
    if (!store.state.permissions.list.length) {
        await store.dispatch('permissions/getPermissions');
    }
    
    switch (to.name) {
        case 'users':
            if (store.state.currentUser.permissions.includes(PERMISSIONS.FULL_ACCESS)) {
                return next();
            } else {
                bus.$emit('show-notification-message', 'Не хватает прав для доступа к этому разделу');
                return false;
            }
        default:
            return next();
    }
}
