<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onSaveClick">
        <div class="mb-3 modal-popup__title">
            Порядок вывода товаров

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <div v-if="popupIsReady" class="products-sorting-modal__selects-group">
            <b-form-select class="form-control" v-for="(product, i) of productsList" :key="i"
                           v-model="productsList[i]" :options="productsOptions"/>
        </div>
        <ui-button @clicked="onSaveClick" class="mb-2">Сохранить</ui-button>
        <div v-if="alert.length" :class="['alert', alertClass]" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    data: () => ({
        alert: '',
        alertClass: '',
        canReset: false,

        productsOptions: [],
        productsList: [],
        popupIsReady: false
    }),
    components: {
        UiButton: () => import('../ui/UiButton')
    },
    computed: {
        products() {
            return this.$store.state.products.list;
        }
    },
    async created() {
        await this.getProducts();
        this.fillSelects();
    },
    methods: {
        ...mapActions('products', ['getProducts', 'saveProductsOrder']),
        close() {
            this.$modal.hide('products-sorting-modal');
        },
        fillSelects() {
            this.productsOptions = Object.values(this.products).map(product => {
                return {
                    text: product.google_id,
                    value: product.id
                };
            });
            for (let i = 0; i < this.productsOptions.length; i++)
                this.productsList[i] = this.productsOptions[i].value;
            this.popupIsReady = true;
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message, isError = true) {
            this.alert = message;
            this.alertClass = isError ? 'alert-danger' : 'alert-success';
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onSaveClick() {
            const idsEntries = {};
            for (let i = 0; i < this.productsList.length; i++) {
                if (idsEntries[this.productsList[i]]) {
                    const productConfig = this.products.find(product => product.id === this.productsList[i]);
                    this.setAlert(`Найден дубликат товара: ${productConfig.google_id}`);
                    return;
                } else {
                    idsEntries[this.productsList[i]] = 1;
                }
            }

            const res = await this.saveProductsOrder(this.productsList);

            if (res.error)
                this.setAlert('Ошибка обновления');
            else
                this.setAlert('Успешно сохранено', false);
        }
    }
};
</script>

<style lang="scss">
.products-sorting-modal {
    &__selects-group {
        flex-direction: column;
        align-items: center;

        .custom-select {
            margin-bottom: 8px;
        }
    }
}
</style>
