<template>
    <div class="page exchanger-page">
        <div class="page__title">
            <nav-back/>
            Обменный курс
            <ui-button color="blue" class="page__add-button" @clicked="showNewItem = true">
                Добавить
            </ui-button>
        </div>

        <exchanger-item v-for="(config, i) of exchangeList" :key="i" v-bind="config"/>

        <h2 v-if="showNewItem" class="mt-5 mb-4">Новое правило</h2>
        <div v-if="showNewItem" class="exchanger-page__new-item">
            <div class="form-group">
                <label>Что меняем</label>
                <b-form-select class="form-control" v-model="selectedFromOption" :options="currenciesOptions"/>
                <input class="form-control exchanger-page-item__rate" type="number" v-model.number="newSourceValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-right exchanger-page__arrow" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>

            <div class="form-group mr-5">
                <label>На что меняем</label>
                <b-form-select class="form-control" v-model="selectedToOption" :options="currenciesOptions"/>
                <input class="form-control exchanger-page-item__rate" type="number" v-model.number="newDestinationValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>

            <div class="exchanger-page__actions">
                <label>Действия</label>
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-check-square page__active-icon" viewBox="0 0 16 16"
                         v-b-tooltip.hover title="Сохранить" @click="onAddClick()"
                    >
                        <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path
                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-trash page__active-icon" viewBox="0 0 16 16"
                         v-b-tooltip.hover title="Удалить" @click="onDeleteClick()"
                    >
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        currenciesOptions: [],
        selectedFromOption: 0,
        selectedToOption: 0,
        newSourceValue: 0,
        newDestinationValue: 0,
        showNewItem: false
    }),
    computed: {
        exchangeList() {
            return this.$store.state.exchanger.list;
        }
    },
    components: {
        NavBack: () => import('../components/navigation/NavBack'),
        UiButton: () => import('../components/ui/UiButton'),
        ExchangerItem: () => import('../components/exchanger/ExchangerItem')
    },
    async created() {
        await this.getCurrencies();
        await this.getExchangesConfig();
        this.fillSelects();
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('exchanger', ['getExchangesConfig', 'addExchangeConfig']),
        fillSelects() {
            this.currenciesOptions = this.$store.state.currencies.list
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });

            this.selectedFromOption = this.selectedToOption = this.currenciesOptions[0].value;
        },
        async onAddClick() {
            const res = await this.addExchangeConfig({
                source_currency_id: parseInt(this.selectedFromOption, 10),
                source_currency_value: parseInt(this.newSourceValue, 10),
                destination_currency_id: parseInt(this.selectedToOption, 10),
                destination_currency_value: parseInt(this.newDestinationValue, 10)
            });

            if (!res.error) {
                bus.$emit('show-notification-message', 'Сохранено');
                this.showNewItem = false;
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        onDeleteClick() {
            this.fillSelects();
            this.showNewItem = false;
        }
    }
};
</script>

<style lang="scss">
.exchanger-page {
    &__new-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .form-control {
            display: flex;
            flex: 0 1 25%;
        }

        .page__active-icon {
            cursor: pointer;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__arrow {
        margin: 68px 32px;
    }
}
</style>
