<template>
    <div class="multi-packs-list mb-4">
        <template v-if="ready">
            <div v-for="(pack, index) of packsList" :key="index" class="multi-packs-list__pack">
                <div class="multi-packs-list__pack-name mb-2">Pack #{{ pack.id }}</div>

                <table class="table table-striped multi-packs-list__table">
                    <thead>
                    <tr>
                        <th scope="col">Изображение</th>
                        <th scope="col">Google ID</th>
                        <th scope="col">Apple ID</th>
                        <th scope="col">Бонус покупки %</th>
                        <th scope="col">Действия</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <img v-if="pack.image_url" :src="pack.image_url" title="Заменить"
                             @click="pack.image_url = ''"/>
                        <input v-else type="file" accept="image/png" @change="loadImage($event, pack.id)"
                               ref="imageUploadButton" class="d-flex"/>
                    </td>
                    <td><input type="text" v-model="pack.google_id" class="form-control"/></td>
                    <td><input type="text" v-model="pack.apple_id" class="form-control"/></td>
                    <td><input type="number" v-model.number="firstBonus[pack.id]" class="form-control"
                               min=0 oninput="validity.valid||(value=0)"
                               @change="onFirstBonusChange(pack.id, $event)"/></td>
                    <td>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Сохранить"
                             @click="onSavePackClick(pack)"
                        >
                            <path
                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path
                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             class="bi bi-trash ms-3 ml-2" viewBox="0 0 16 16" v-b-tooltip.hover title="Удалить"
                             @click="onDeletePackClick(pack.id)"
                        >
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </td>
                </tr>
                </tbody>
            </table>

                <table class="table table-striped multi-packs-list__table multi-packs-list__table--rewards">
                    <thead>
                    <tr>
                        <th scope="col">Валюта</th>
                        <th scope="col">Кол-во</th>
                        <th scope="col"></th>
                        <th scope="col">Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(reward, i) of pack.rewards" :key="reward.id">
                        <td>
                            <b-form-select class="form-control" v-model="selectedCurrencies[pack.id][i]"
                                           :options="currenciesOptions"/>
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model.number="rewardValues[pack.id][i]"
                                   min=1 oninput="validity.valid||(value=0)"
                            />
                        </td>
                        <td></td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 class="bi bi-trash ms-3 ml-2" viewBox="0 0 16 16" v-b-tooltip.hover title="Удалить"
                                 @click="onDeletePackRewardClick(pack.id, reward.id)"
                            >
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="d-flex">
                    <ui-button class="multi-packs-list__add-button" @clicked="onAddPackRewardClick(pack.id)">
                        Добавить валюту
                    </ui-button>
                </div>
            </div>
        </template>
        <div class="d-flex" style="width: 100%;">
            <ui-button class="multi-packs-list__add-button" @clicked="onAddPackClick">
                Добавить пак
            </ui-button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import {openMultiPackModal} from '@/modals/addMultiPackModal';
import bus from '@/plugins/bus';
import {toDataURL, uploadImage} from '@/utils';

export default {
    props: {
        packs: Array
    },
    data: () => ({
        ready: false,
        packsList: [],
        currenciesOptions: [],
        selectedCurrencies: {},
        rewardValues: {},
        firstBonus: {}
    }),
    computed: {
        currencies() {
            return this.$store.state.currencies.list;
        },
    },
    components: {
        UiButton: () => import('../../components/ui/UiButton')
    },
    async created() {
        await this.getCurrencies();
        this.updateComponent();
        bus.$off('products-list-updated').$on('products-list-updated', this.onProductsUpdated);
    },
    beforeDestroy() {
        bus.$off('products-list-updated', this.onProductsUpdated);
    },
    methods: {
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('products', ['updateProduct', 'deleteProduct']),
        updateComponent() {
            this.packsList = this.packs;
            this.packsList.forEach(pack => {
                pack.image_url.length && toDataURL(pack.image_url, result => pack.image_url = result);
                this.firstBonus[pack.id] = Math.max(pack.first_bonus, 0);
            });
            this.fillSelects();
        },
        onProductsUpdated(newProduct) {
            newProduct && this.updateComponent();
        },
        fillSelects() {
            this.currenciesOptions = this.$store.state.currencies.list
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });
            this.packsList.forEach(pack => {
                this.selectedCurrencies[pack.id] = pack.rewards.map(reward => reward.id);
                this.rewardValues[pack.id] = pack.rewards.map(reward => reward.value);
            });
            this.ready = true;
        },
        onAddPackRewardClick(productId) {
            this.packsList.find(p => p.id === productId).rewards.push({
                id: 1,
                value: 0
            });
            this.fillSelects();
        },
        onDeletePackRewardClick(productId, currencyId) {
            if (!confirm('Подтвердите удаление'))
                return;
            this.packsList.some(pack => {
                if (pack.id === productId) {
                    pack.rewards = pack.rewards.filter(reward => reward.id !== currencyId);
                    return true;
                }
            });
            this.forceUpdate();
        },
        async onSavePackClick(pack) {
            const currenciesEntries = {};
            const rewards = [];

            for (let i = 0; i < this.selectedCurrencies[pack.id].length; i++) {
                if (!this.rewardValues[pack.id][i]) {
                    bus.$emit('show-notification-message', 'Не должно быть нулевых значений валют');
                    return;
                }

                if (currenciesEntries[this.selectedCurrencies[pack.id][i]]) {
                    const currencyConfig = this.currencies.find(c => c.id === this.selectedCurrencies[pack.id][i]);
                    bus.$emit('show-notification-message', `Найдены дубликаты наград: ${currencyConfig.name}`);
                    return;
                } else
                    currenciesEntries[this.selectedCurrencies[i]] = 1;

                rewards.push({
                    id: parseInt(this.selectedCurrencies[pack.id][i], 10),
                    value: parseInt(this.rewardValues[pack.id][i], 10)
                });
            }

            const data = {
                id: pack.id,
                google_id: pack.google_id,
                apple_id: pack.apple_id,
                image: pack.image_url.replace(/^data:.+;base64,/, ''),
                first_bonus: parseInt(this.firstBonus[pack.id], 10) || 0,
                rewards,
            };

            if (pack.image_url.includes(';base64,'))
                data.image = pack.image_url.replace(/^data:.+;base64,/, '');
            else if (!pack.image_url.includes('https://')) {
                alert('Необходимо загрузить иконку продукта');
                return;
            }

            const res = await this.updateProduct(data);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Обновлено');
                this.$forceUpdate();
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        async onDeletePackClick(productId) {
            if (!confirm('Подтвердите удаление'))
                return;
            const res = await this.deleteProduct(productId);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Удалено');
                this.packsList = this.packsList.filter(pack => pack.id !== productId);
                this.forceUpdate();
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        onAddPackClick() {
            openMultiPackModal();
        },
        loadImage(event, productId) {
            uploadImage(event, result => {
                const pack = this.packsList.find(product => product.id === productId);
                pack.image_url = result;
            });
        },
        forceUpdate() {
            this.$forceUpdate();
            this.fillSelects();
        },
        onFirstBonusChange(packId, event) {
            let value = parseInt(event.target.value, 10);
            if (isNaN(value)) value = 0;
            this.firstBonus[packId] = value;
        }
    }
};
</script>

<style lang="scss">
.multi-packs-list {
    &__pack-name {
        text-align: left;
    }

    &__table {
        td {
            vertical-align: middle;
            width: 25%;
        }
    }

    &__add-button {
        float: left;
        min-width: 200px;
    }

    &__pack {
        border-bottom: 1px solid #2d4c70;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    img {
        cursor: pointer;
        max-width: 160px;
    }
}
</style>
