<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onSaveClick">
        <div class="mb-3 modal-popup__title">
            Выставить прогресс

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <template v-if="progressData.length > 0 && pageIsReady">
            <div class="form-group d-flex flex-column">
                <label class="text-left">ID истории</label>
                <b-form-select v-model="selectedStoryId" :options="storiesOptions"
                               class="players-page__find-value"/>
            </div>

            <div class="form-group d-flex flex-column">
                <label class="text-left">ID главы</label>
                <b-form-select v-model="selectedChapterId" :options="chapterOptions"
                               class="players-page__find-value"/>
            </div>

            <div class="form-group d-flex flex-column">
                <label class="text-left">ID ноды</label>
                <b-form-select v-model="selectedProgressId" :options="nodeOptions"
                               class="players-page__find-value"/>
            </div>

            <div class="form-group text-left mt-2 ml-1">
                <input type="checkbox" v-model="needToResetChapter" id="needToResetChapter" class="mr-2"/>
                <label for="needToResetChapter">Сбросить прогресс главы</label>
            </div>

            <ui-button class="mt-0 mb-2" @clicked="onSaveClick" :disabled="pendingRequest">Сохранить</ui-button>
        </template>
        <template v-else>
            <div class="mb-2">У пользователя нет историй с прогрессом</div>
        </template>

        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>

import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: ['playerId', 'progressData'],
    data: () => ({
        storyId: Number,
        chapterId: Number,
        nodeId: Number,
        needToResetChapter: false,
        alert: '',
        canReset: false,
        pendingRequest: false,
        pageIsReady: false,

        selectedStoryId: 0,
        storiesOptions: [],

        selectedChapterId: 0,
        chapterOptions: [],

        selectedProgressId: 0,
        nodeOptions: [],

        storyProgressData: {},
        selectedChapterData: {},
        availableChapters: [],
        storyData: {}
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    async mounted() {
        this.$refs.firstInput && this.$refs.firstInput.focus();
        if (!this.progressData.length) return;
        await this.getStories();
        this.fillSelects();
    },
    watch: {
        selectedStoryId(storyId) {
            this.storyProgressData = this.progressData.find(data => data.story_id == storyId);
            this.availableChapters = this.storyProgressData.chapters.map(data => data.chapters_id);
            this.storyData = this.$store.state.stories.list.find(story => story.id == storyId);

            this.chapterOptions = this.storyData.chapters.filter(chapter => this.availableChapters.includes(chapter.id))
                .map(chapter => {
                    return {
                        text: chapter.title,
                        value: chapter.id
                    };
                });
            this.selectedChapterId = this.chapterOptions[0].value;
        },
        selectedChapterId(chapterId) {
            this.selectedChapterData = this.storyProgressData.chapters.find(data => data.chapters_id == chapterId);
            this.nodeOptions = this.selectedChapterData.nodes.map(nodeData => {
                return {
                    text: nodeData.node,
                    value: nodeData.progress_id
                };
            });
            this.selectedProgressId = this.nodeOptions[0].value;
        }
    },
    methods: {
        ...mapActions('players', ['setUserProgress']),
        ...mapActions('stories', ['getStories']),
        fillSelects() {
            const availableStoryIds = this.progressData.map(data => data.story_id);
            this.storiesOptions = this.$store.state.stories.list
                .filter(story => availableStoryIds.includes(story.id))
                .map(story => {
                    return {
                        value: story.id,
                        text: story.title
                    };
                });
            this.selectedStoryId = this.storiesOptions[0].value;
            this.pageIsReady = true;
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onSaveClick() {
            const postData = {
                story_id: this.selectedStoryId,
                chapter_id: this.selectedChapterId,
                progress_id: this.needToResetChapter ? null : this.selectedProgressId,
                is_reset: this.needToResetChapter
            };

            this.pendingRequest = true;

            const res = await this.setUserProgress({
                playerId: this.playerId,
                postData
            });

            this.pendingRequest = false;

            if (!res.error) {
                bus.$emit('show-notification-message', 'Обновлено');
                this.close();
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        close() {
            this.$modal.hide('set-user-chapter-modal');
        },
        onStorySelected(event) {
            this.selectedStoryId = parseInt(event.target.dataset.value, 10);
            this.selectedStoryName = this.storiesOptions.find(story => story.id == this.selectedStoryId).name;
        }
    }
};
</script>
