<template>
    <div class="nav-bar-left"
        :class="{'nav-bar-left--hidden': hidden}"
    >
        <span aria-hidden="true" class="nav-bar-left__close" @click="onCloseClick">&times;</span>
        <div class="nav-bar-left__logo" @click="onLogoClick">NOVEL ADMIN</div>
        <div class="nav-bar-left__link-wrapper">
            <input type="text" class="form-control nav-bar-left__filter" v-model="filterText"/>

            <a :href="apiUrl" target="_blank"
               class="nav-bar-left__link nav-bar-left__open-api-link">
                Open API
            </a>

            <div class="nav-bar-left__link" v-for="(link, i) of filteredLinks" :key="i" @click="onLinkClick(link)"
                 :class="{
                'nav-bar-left__link--active': isLinkActive(link),
                'nav-bar-left__link--disabled': link.disabled,
             }"
            >
                {{ link.title }}
            </div>
        </div>
    </div>
</template>

<script>
import LINKS from '@/router/links';
import bus from '@/plugins/bus';
import api from '@/api';

export default {
    data: () => ({
        links: LINKS,
        hidden: false,
        filterText: '',
    }),
    computed: {
        filteredLinks() {
            if (!this.filterText.length)
                return this.links;
            return this.links.filter(link => link.title.toLowerCase().includes(this.filterText.toLowerCase()));
        },
        apiUrl() {
            return `${window.location.origin}/api/openapi/admin`;
        }
    },
    created() {
        bus.$off('menu-clicked', this.onMenuClick).$on('menu-clicked', this.onMenuClick);
    },
    beforeDestroy() {
        bus.$off('menu-clicked', this.onMenuClick);
    },
    methods: {
        async onLinkClick(linkData) {
            if (this.$route.path === linkData.link) {
                return;
            }

            this.$store.commit('UPDATE', {routerHasHistory: true});

            await this.$router.push(linkData.link);
        },
        isLinkActive(linkData) {
            return this.$route.path === linkData.link;
        },
        onLogoClick() {
            this.$router.push('/');
        },
        onCloseClick() {
            this.hidden = true;
        },
        onMenuClick() {
            this.hidden = false;
        }
    }
};
</script>

<style lang="scss">
.nav-bar-left {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1;
    will-change: transform;
    transition: .35s transform ease;

    &--hidden {
        transform: translateX(-200px);

        .nav-bar-left__close {
            display: none;
        }
    }

    &__filter {
        display: flex;
        margin: 8px auto 4px;
        width: 90%;
        font-size: 0.75em;
    }

    &__close {
        position: absolute;
        width: 72px;
        height: 64px;
        top: 0;
        left: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
    }


    &__logo {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 64px;
        background: #2E4D71;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 25px;
        text-align: left;
        color: #fff;
    }

    &__link-wrapper {
        height: 100vh;
        position: relative;
        padding-bottom: 72px;

        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__link {
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            color: #3b85d2;
        }

        &--active,
        &--active:hover {
            background: #C4DFFF;
            color: #2D4C70;
        }

        &--disabled {
            color: #ec0909;
        }
    }

    &__open-api-link {
        box-shadow: 0 1px 0 0 #2d4c70 inset, 0 -1px 0 0 #2d4c70 inset;
        color: #2D4C70;
        margin: 12px 0;

        &:hover {
            text-decoration: none;
        }
    }
}
</style>
