import Vue from 'vue';
import api from '@/api';
import {UPDATE, update} from '../utils';
import {findWithAttr} from '@/utils';

const state = {
    /**
     * [...{id: number, title: string, description: string, currency: id, price: number}]
     */
    list: []
};

const getters = {};

const actions = {
    update,
    
    async getPrices({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/prices')
                .then(response => {
                    commit('UPDATE', {list: response.prices});
                    resolve(response.prices);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async addPrice({commit}, priceData) {
        return new Promise(resolve => {
            api.post('/prices', priceData)
                .then(price => {
                    commit('ADD_PRICE_CONFIG', price);
                    resolve(price);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async updatePrice({commit}, priceData) {
        return new Promise(resolve => {
            api.patch(`/prices/${priceData.id}`, priceData)
                .then(price => {
                    commit('UPDATE_PRICE_CONFIG', {
                        id: priceData.id,
                        description: priceData.description,
                        money: price.money
                    });
                    resolve(price);
                })
                .catch(error => resolve({error}));
        });
    }
};

const mutations = {
    UPDATE,
    
    ADD_PRICE_CONFIG(state, price) {
        Vue.set(state.list, state.list.length, price);
    },
    
    UPDATE_PRICE_CONFIG(state, newConfig) {
        const idx = findWithAttr(state.list, 'id', newConfig.id);
        
        if (idx > -1) {
            Vue.set(state.list, idx, newConfig);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
