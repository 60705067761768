import Vue from 'vue';
import Vuex from 'vuex';

import currentUser from '@/store/modules/currentUser';
import permissions from '@/store/modules/permissions';
import stories from '@/store/modules/stories';
import authors from '@/store/modules/authors';
import users from '@/store/modules/users';
import genres from '@/store/modules/genres';
import currencies from '@/store/modules/currencies';
import skills from '@/store/modules/skills';
import categories from '@/store/modules/categories';
import exchanger from '@/store/modules/exchanger';
import prices from '@/store/modules/prices';
import products from '@/store/modules/products';
import players from '@/store/modules/players';
import dailyBonus from '@/store/modules/dailyBonus';

import createPersistedState from 'vuex-persistedstate';
import {update, UPDATE} from '@/store/utils';
import api from '@/api';

Vue.use(Vuex);

const state = {
    routerHasHistory: false,
    configs: Object
};

const getters = {};

const mutations = {
    UPDATE,
    
    UPDATE_CONFIG(state, config) {
        Object.entries(config).forEach(([key, value]) => {
            Vue.set(state.configs, key, value);
        });
    }
};

const actions = {
    update,
    
    async getConfigs({commit, state}) {
        return new Promise(resolve => {
            if (Object.keys(state.configs).length) {
                resolve(state.configs);
                return;
            }
            
            api.fetch('/config')
                .then(configs => {
                    commit('UPDATE', {configs});
                    resolve(configs);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async saveConfig({commit}, data) {
        return new Promise(resolve => {
            api.patch('/config', data)
                .then(config => {
                    commit('UPDATE_CONFIG', config);
                    resolve(config);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    }
};

const modules = {
    permissions,
    currentUser,
    currencies,
    categories,
    stories,
    authors,
    genres,
    skills,
    users,
    exchanger,
    prices,
    products,
    players,
    dailyBonus,
};

const store = new Vuex.Store({
    namespaced: true,
    name: 'store',
    state,
    getters,
    actions,
    mutations,
    modules,
    plugins: [createPersistedState({
        paths: [
            'currentUser.id',
            'currentUser.token',
            'currentUser.permissions',
            'currentUser.email',
            'stories.chaptersSortingOrder',
        ],
    })],
});

if (module.hot) {
    module.hot.accept([
        './modules/currentUser',
        './modules/permissions',
        './modules/categories',
        './modules/currencies',
        './modules/stories',
        './modules/authors',
        './modules/genres',
        './modules/skills',
        './modules/users',
        './modules/exchanger',
        './modules/prices',
        './modules/products',
        './modules/players',
        './modules/dailyBonus',
    ], () => {
        const newCurrentUserModule = require('./modules/currentUser').default;
        const newPermissionsModule = require('./modules/permissions').default;
        const newCurrenciesModule = require('./modules/currencies').default;
        const newCategoriesModule = require('./modules/categories').default;
        const newStoriesModule = require('./modules/stories').default;
        const newAuthorsModule = require('./modules/authors').default;
        const newGenresModule = require('./modules/genres').default;
        const newSkillsModule = require('./modules/skills').default;
        const newUsersModule = require('./modules/users').default;
        const newExchangerModule = require('./modules/exchanger').default;
        const newPricesModule = require('./modules/prices').default;
        const newProductsModule = require('./modules/products').default;
        const newPlayersModule = require('./modules/players').default;
        const dailyBonusModule = require('./modules/dailyBonus').default;
        
        store.hotUpdate({
            modules: {
                permissions: newPermissionsModule,
                currentUser: newCurrentUserModule,
                categories: newCategoriesModule,
                currencies: newCurrenciesModule,
                stories: newStoriesModule,
                authors: newAuthorsModule,
                genres: newGenresModule,
                skills: newSkillsModule,
                users: newUsersModule,
                exchanger: newExchangerModule,
                prices: newPricesModule,
                products: newProductsModule,
                players: newPlayersModule,
                dailyBonus: dailyBonusModule,
            },
        });
    });
}

export default store;
