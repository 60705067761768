import Popup from "@/lib/Popup";
import ProductsSortingModal from '@/components/modals/ProductsSortingModal';

export const openProductsSortingModal = _ => {
    const popup = new Popup({
        component: ProductsSortingModal,
        params: {
            transition: 'scale',
            name: 'products-sorting-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openProductsSortingModal};
