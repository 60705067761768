import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Home from '../views/Home.vue';

import auth from './middlewares/auth';
import hasPermissions from './middlewares/hasPermissions';
import middlewarePipeline from './middlewarePipeline';

import LINKS from './links';

Vue.use(VueRouter);

const DEFAULT_TITLE = process.env.VUE_APP_SITE_TITLE;

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "stories" */ '../views/Stories.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/currencies',
        name: 'currencies',
        component: () => import(/* webpackChunkName: "currencies" */ '../views/Currencies.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/genres',
        name: 'genres',
        component: () => import(/* webpackChunkName: "genres" */ '../views/Genres.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/skills',
        name: 'skills',
        component: () => import(/* webpackChunkName: "skills" */ '../views/Skills.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/authors',
        name: 'authors',
        component: () => import(/* webpackChunkName: "authors" */ '../views/Authors.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/story',
        name: 'story',
        props: true,
        component: () => import(/* webpackChunkName: "story" */ '../views/Story.vue'),
        meta: {
            middleware: [auth, hasPermissions]
        },
        children: [
            {
                path: ':storyId',
                name: 'story-view',
                component: import(/* webpackChunkName: "story" */ '../views/Story.vue'),
                meta: {
                    middleware: [auth, hasPermissions]
                },
            },
        ]
    },
    {
        path: '/chapters',
        name: 'chapters',
        component: () => import(/* webpackChunkName: "chapters" */ '../views/Chapter.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/first-story',
        name: 'first-story',
        component: () => import(/* webpackChunkName: "first_story" */ '../views/FirstStory.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/economy',
        name: 'economy',
        component: () => import(/* webpackChunkName: "economy" */ '../views/Economy.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/exchanger',
        name: 'exchanger',
        component: () => import(/* webpackChunkName: "exchanger" */ '../views/Exchanger.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/prices',
        name: 'prices',
        component: () => import(/* webpackChunkName: "prices" */ '../views/Prices.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/recommended-stories',
        name: 'recommended-stories',
        component: () => import(/* webpackChunkName: "products" */ '../views/RecommendedStories.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/players',
        name: 'players',
        component: () => import(/* webpackChunkName: "products" */ '../views/Players.vue'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/season-first-purchase',
        name: 'season-first-purchase',
        component: () => import(/* webpackChunkName: "season_first_purchase" */ '../views/SeasonFirstPurchase'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/keys-timer',
        name: 'keys-timer',
        component: () => import(/* webpackChunkName: "keys_timer" */ '../views/KeysTimer'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/sale-action',
        name: 'sale-action',
        component: () => import(/* webpackChunkName: "sale_action" */ '../views/SaleAction'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/daily-bonus',
        name: 'daily-bonus',
        component: () => import(/* webpackChunkName: "daily_bonus" */ '../views/DailyBonus'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
    {
        path: '/fake-stories',
        name: 'fake-stories',
        component: () => import(/* webpackChunkName: "fake_stories" */ '../views/FakeStories'),
        props: true,
        meta: {
            middleware: [auth, hasPermissions]
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next();
    }
    
    if (!store.state.currentUser.token && to.name !== 'login') {
        return next('/login');
    }
    
    const middleware = to.meta.middleware;
    
    const context = {to, from, next, store};
    
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        const config = LINKS.find(config => config.link === to.name);
        document.title = config ? `Админка - ${config.title}` : DEFAULT_TITLE;
        
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    });
});

export default router;
