import Vue from 'vue';

import {UPDATE, update} from '../utils';
import {findWithAttr} from '@/utils';

import api from '@/api';

const state = {
    list: []
};

const getters = {
    getCurrencyName: state => id => state.list[id] ? state.list[id].name : 'Ожидание',
    currency: state => id => state.list[id] || {}
};

const actions = {
    update,
    
    async getCurrencies({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/currencies').then(list => {
                const free = {id: 0, name: 'Бесплатно', start_value: 0};
                commit('UPDATE', {list: [free, ...list]});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addCurrency({commit}, data) {
        return new Promise(resolve => {
            api.post('/currencies', data).then(currency => {
                commit('UPDATE_CURRENCY', currency);
                resolve(currency);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async updateCurrency({commit}, data) {
        return new Promise(resolve => {
            api.put(`/currencies/${data.id}`, data).then(currency => {
                commit('UPDATE_CURRENCY', currency);
                resolve(currency);
            }).catch(error => {
                resolve({error});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    UPDATE_CURRENCY(state, currency) {
        let idx = findWithAttr(state.list, 'id', currency.id);
        
        if (idx > -1) {
            Vue.set(state.list, idx, currency);
        } else {
            Vue.set(state.list, state.list.length, currency);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
