import Popup from "@/lib/Popup";
import AddGenreModal from '@/components/modals/AddGenreModal';

export const openAddGenreModal = Genre => {
    const popup = new Popup({
        component: AddGenreModal,
        props: {Genre},
        params: {
            transition: 'scale',
            name: 'add-genre-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddGenreModal};
