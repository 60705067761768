<template>
    <div class="modal-popup" @keyup.13="onSaveClick">
        <div class="mb-3 modal-popup__title">
            Редактирование валюты

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <input type="text" class="form-control mb-2" placeholder="Название" v-model="name" ref="input">
        <label class="modal-popup__label">Повышенная жадность</label>
        <input type="number" class="form-control" v-model.number="greed" placeholder="Повышенная жадность"
               min=0 oninput="validity.valid||(value=0)">
        <label class="modal-popup__label">Стартовый баланс</label>
        <input type="number" class="form-control mb-2" placeholder="Стартовый баланс" v-model.number="value"
               min=0 oninput="validity.valid||(value=0)"
        >
        <ui-button class="mt-3 mb-2" @clicked="onSaveClick">Сохранить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: ['currency'],
    data: () => ({
        name: '',
        value: 0,
        greed: 0,
        alert: ''
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    mounted() {
        this.name = this.currency.name;
        this.greed = this.currency.greed;
        this.value = this.currency.start_value;
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('currencies', ['updateCurrency']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onSaveClick() {
            if (this.name === '') {
                this.setAlert('Необходимо ввести название');
                return;
            }

            const data = {
                id: this.currency.id,
                name: this.name,
                greed: parseInt(this.greed, 10),
                start_value: parseInt(this.value, 10)
            };

            const res = await this.updateCurrency(data);

            if (res.error) {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            } else {
                this.close();
            }
        },
        close() {
            this.$modal.hide('edit-currency-modal');
        }
    }
};
</script>
