import {UPDATE, update} from '../utils';
import api from '@/api';

const state = {};

const getters = {};

const actions = {
    update,
    
    async getPlayer({commit}, {idType, id}) {
        return new Promise(resolve => {
            api.fetch(`/player/${idType}/${id}`).then(player => {
                resolve(player);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async resetPlayer({commit}, playerId) {
        return new Promise(resolve => {
            api.patch(`/player/${playerId}/reset`).then(player => {
                resolve(player);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async setTesterFlag({commit}, {playerId, isTester}) {
        return new Promise(resolve => {
            api.patch(`/player/${playerId}/tester`, {is_tester: isTester}).then(player => {
                resolve(player);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async setUserProgress(_, {playerId, postData}) {
        return new Promise(resolve => {
            api.post(`/player/${playerId}/progress`, postData).then(_ => {
                resolve({status: 'ok'});
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async getUserProgress({}, playerId) {
        return new Promise(resolve => {
            api.fetch(`/player/${playerId}/progress`).then(progress => {
                resolve(progress);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async updateUserCurrency({}, {playerId, money}) {
        return new Promise(resolve => {
            api.patch(`/player/${playerId}/money`, {money}).then(newBalance => {
                resolve(newBalance);
            }).catch(error => {
                resolve({error});
            });
        });
    }
};

const mutations = {
    UPDATE,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
