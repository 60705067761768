<template>
    <div class="page first-story-page">
        <div class="page__title">
            <nav-back/>
            Настройка первой истории после инсталла
        </div>

        <label class="mt-4">История 18+</label>
        <b-form-select v-model="selectedAdultStory" :options="storiesOptions"/>

        <label class="mt-3">Альтернативная история</label>
        <b-form-select v-model="selectedAlternateStory" :options="storiesOptions"/>

        <ui-button @clicked="onSaveClick" class="first-story-page__button">Применить</ui-button>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    data: () => ({
        storiesOptions: [
            {
                value: 0,
                text: 'Ожидание'
            }
        ],
        selectedAdultStory: 0,
        selectedAlternateStory: 0,

        adultStoryChanged: false,
        alternateStoryChanged: false,
    }),
    components: {
        NavBack: () => import('../components/navigation/NavBack'),
        UiButton: () => import('../components/ui/UiButton')
    },
    computed: {
        ...mapState(['configs']),
        stories() {
            return this.$store.state.stories.list;
        }
    },
    created() {
        document.addEventListener('keyup', this.checkEnter);
    },
    beforeDestroy() {
        document.removeEventListener(this.checkEnter);
    },
    async mounted() {
        await this.getConfigs();
        await this.getStories();
        this.fillSelects();
    },
    watch: {
        selectedAdultStory(value, oldValue) {
            if (oldValue) {
                this.adultStoryChanged = true;
            }
        },
        selectedAlternateStory(value, oldValue) {
            if (oldValue) {
                this.alternateStoryChanged = true;
            }
        }
    },
    methods: {
        ...mapActions(['getConfigs', 'saveConfig']),
        ...mapActions('stories', ['getStories']),
        checkEnter(event) {
            if (event.keyCode === 13) {
                this.onSaveClick();
            }
        },
        fillSelects() {
            this.storiesOptions = this.stories.map(story => {
                return {
                    value: story.id,
                    text: story.title
                };
            });

            this.selectedAdultStory = this.configs.start_story_adult;
            this.selectedAlternateStory = this.configs.start_story;
        },
        async onSaveClick() {
            let data = {};

            if (this.adultStoryChanged) {
                data.start_story_adult = parseInt(this.selectedAdultStory, 10);
            }

            if (this.alternateStoryChanged) {
                data.start_story = parseInt(this.selectedAlternateStory, 10);
            }

            if (!Object.keys(data).length) {
                return;
            }

            const res = await this.saveConfig(data);

            if (!res.error) {
                bus.$emit('show-notification-message', 'Сохранено');
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        }
    }
};
</script>

<style lang="scss">
.first-story-page {
    label {
        text-align: left;
    }

    select {
        max-width: 25%;
    }

    &__button {
        max-width: fit-content;
        margin-top: 16px;
    }
}
</style>
