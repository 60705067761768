import Vue from 'vue';

import {UPDATE, update} from '../utils';
import {clone, findWithAttr} from '@/utils';

import api from '@/api';

const state = {
    list: []
};

const getters = {
    getCategoryById: state => id => state.list.find(category => category.id === id)
};

const actions = {
    update,
    
    async getCategories({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/categories').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addCategory({commit}, name) {
        return new Promise(resolve => {
            api.post('/categories', {name}).then(category => {
                commit('UPDATE_CATEGORY', category);
                resolve(category);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async updateCategory({commit}, data) {
        return new Promise(resolve => {
            api.put(`/categories/${data.id}`, data).then(category => {
                commit('UPDATE_CATEGORY', category);
                resolve(category);
            }).catch(error => {
                resolve({error});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    UPDATE_CATEGORY(state, category) {
        let idx = findWithAttr(state.list, 'id', category.id);
        
        if (idx > -1) {
            Vue.set(state.list, idx, category);
        } else {
            Vue.set(state.list, state.list.length, category);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
