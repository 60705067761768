import Vue from 'vue';

import {UPDATE, update} from '../utils';
import {findWithAttr} from '@/utils';

import api from '@/api';

const state = {
    list: []
};

const getters = {};

const actions = {
    update,
    
    async getSkills({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/skills').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addSkills({commit}, data) {
        return new Promise(resolve => {
            api.post('/skills', data).then(skill => {
                commit('UPDATE_SKILL', skill);
                resolve(skill);
            }).catch(error => {
                resolve({error});
            });
        });
    },
    
    async updateSkills({commit}, data) {
        return new Promise(resolve => {
            api.put(`/skills/${data.id}`, data).then(skill => {
                commit('UPDATE_SKILL', skill);
                resolve(skill);
            }).catch(error => {
                resolve({error});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    UPDATE_SKILL(state, skill) {
        let idx = findWithAttr(state.list, 'id', skill.id);
        
        if (idx > -1) {
            Vue.set(state.list, idx, skill);
        } else {
            Vue.set(state.list, state.list.length, skill);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
