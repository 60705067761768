import Vue from 'vue';
import api from '@/api';

import {UPDATE, update} from '../utils';
import {findWithAttr} from '@/utils';

const state = {
    /**
     * [...{id, source_currency_id, source_currency_value, destination_currency_id, destination_currency_value}]
     */
    list: []
};

const getters = {
    exchangeList: state => state.list
};

const actions = {
    update,
    
    async getExchangesConfig({commit, state}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/exchanges')
                .then(list => {
                    commit('UPDATE', {list});
                    resolve(list);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async addExchangeConfig({commit}, config) {
        return new Promise(resolve => {
            api.post('/exchanges', config)
                .then(config => {
                    commit('ADD_EXCHANGE_CONFIG', config);
                    resolve(config);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async updateExchangeConfig({commit}, config) {
        return new Promise(resolve => {
            api.put(`/exchanges/${config.id}`, config)
                .then(config => {
                    commit('UPDATE_EXCHANGE_CONFIG', config);
                    resolve(config);
                })
                .catch(error => resolve({error}));
        });
    },
    
    async deleteExchangeConfig({commit}, id) {
        return new Promise(resolve => {
            api.delete(`/exchanges/${id}`)
                .then(response => {
                    commit('DELETE_EXCHANGE_CONFIG', id);
                    resolve({success: 1});
                })
                .catch(error => resolve({error}));
        });
    },
};

const mutations = {
    UPDATE,
    
    ADD_EXCHANGE_CONFIG(state, config) {
        Vue.set(state.list, state.list.length, config);
    },
    
    UPDATE_EXCHANGE_CONFIG(state, newConfig) {
        const idx = findWithAttr(state.list, 'id', newConfig.id);
        
        if (idx > -1) {
            Vue.set(state.list, idx, newConfig);
        }
    },
    
    DELETE_EXCHANGE_CONFIG(state, id) {
        const idx = findWithAttr(state.list, 'id', id);
    
        if (idx > -1) {
            Vue.delete(state.list, idx);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
