const LINKS = [
    {
        title: 'Истории',
        link: '/stories'
    },
    {
        title: 'Валюта',
        link: '/currencies'
    },
    {
        title: 'Категории',
        link: '/categories'
    },
    {
        title: 'Жанры',
        link: '/genres'
    },
    {
        title: 'Авторы',
        link: '/authors'
    },
/*    {
        title: 'Характеристики',
        link: '/skills'
    },*/
    {
        title: 'Пользователи',
        link: '/users'
    },
    {
        title: 'Справочник цен',
        link: '/prices',
    },
    {
        title: 'Стартовые истории',
        link: '/first-story',
    },
    {
        title: 'Экономика',
        link: '/economy',
    },
    {
        title: 'Обменный курс',
        link: '/exchanger',
    },
    {
        title: 'Товары',
        link: '/products',
    },
    {
        title: 'Рекомендованные истории',
        link: '/recommended-stories',
    },
    {
        title: 'Игроки',
        link: '/players',
    },
    {
        title: 'Сезонные покупки',
        link: '/season-first-purchase',
    },
    {
        title: 'Таймер ключей',
        link: '/keys-timer',
    },
    {
        title: 'Настройки акций',
        link: '/sale-action',
    },
    {
        title: 'Ежедневный бонус',
        link: '/daily-bonus',
    },
    {
        title: 'Фейковые истории',
        link: '/fake-stories',
    },
];

export default LINKS;
