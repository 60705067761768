import {UPDATE, update} from '../utils';
import api from '@/api';

const state = {
    id: 0,
    email: '',
    token: null,
    permissions: []
};

const getters = {};

const actions = {
    update,
    
    async auth({commit}, params) {
        return new Promise(resolve => {
            api.post('/users/auth', params).then(({id, token, permissions}) => {
                commit('UPDATE', {id, token, permissions, email: params.email});
                resolve({success: 1});
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
};

const mutations = {
    UPDATE,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
