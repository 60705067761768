<template>
    <div class="page players-page" @keyup.13="findPlayer">
        <div class="page__title">
            <nav-back/>
            {{ playerData ? `Данные по игроку #${playerData.id}` : 'Поиск игрока' }}
            <div style="position: absolute;right: 0;">
                <ui-button v-if="playerData" class="players-page__reset-button mr-2" @clicked="onSetChapterClick">
                    Выставить прогресс
                </ui-button>
                <ui-button v-if="playerData" class="players-page__reset-button" @clicked="onResetPlayerClick">
                    Обнулить игрока
                </ui-button>
                <ui-button v-if="playerData" class="players-page__reset-button ml-2" @clicked="playerData = null">
                    Поиск
                </ui-button>
            </div>
        </div>
        <template v-if="!playerData">
            <div class="form-group players-page__search-block">
                <label class="players-page__label">Соц. сеть</label>
                <b-form-select v-model="selectedSocialOption" :options="socialOptions"
                               class="players-page__find-value"/>
            </div>
            <div class="form-group players-page__search-block">
                <label class="players-page__label">ID</label>
                <input type="text" v-model="socialId" class="form-control players-page__find-value">
            </div>
            <ui-button class="players-page__find-button" @clicked="findPlayer">Найти</ui-button>
        </template>
        <template v-else>
            <div class="mb-3">
                <div class="players-page__field-title">
                    Тестировщик <input type="checkbox" v-model="playerData.is_tester"
                                       @change="onTesterFlagChanged($event)"/>
                </div>
            </div>
            <div class="mb-3">
                <div class="players-page__field-title">Дата регистрации: {{ registrationDate }}</div>
            </div>
            <div class="mb-3">
                <div class="players-page__field-title">
                    Контент для взрослых: {{ playerData.adult ? 'разрешен' : 'отклонен' }}
                </div>
            </div>
            <div class="mb-3">
                <div class="players-page__field-title mb-3">
                    Баланс
                    <ui-button class="ml-5" @clicked="setUserCurrency">Обновить</ui-button>
                </div>
                <div class="players-page__list">
                    <div v-for="(money, i) of playerData.monies" class="players-page__list-row">
                        <div class="text-left font-weight-bold">{{ getCurrencyName(money.id) }}:</div>
                        <div>
                            <input v-model.number="money.value" class="form-control"
                                   min=0 oninput="validity.valid||(value=0)"
                            />
                        </div>
                        <!--                        <div class="ml-2" style="min-width: auto;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                                         class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover
                                                         title="TODO: Сохранить"
                                                         style="cursor: pointer;"
                                                         @click="setUserCurrency(money)"
                                                    >
                                                        <path
                                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                        <path
                                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                    </svg>
                                                </div>-->
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="recommendedStoryData">
                <div class="players-page__field-title">
                    Рекомендовано к чтению:
                    <a :href="`/story/${recommendedStoryData.id}`" target="_blank">
                        #{{ recommendedStoryData.id }} {{ recommendedStoryData.title }}
                    </a>
                </div>
            </div>
            <div class="mb-3" v-if="activeStoryData">
                <div class="players-page__field-title">
                    Сейчас читает:
                    <a :href="`/story/${activeStoryData.id}`" target="_blank">
                        #{{ activeStoryData.id }} {{ activeStoryData.title }}
                    </a>
                    -
                    <a :href="`/chapter/${playerData.last_chapter.id}`" target="_blank">
                        Глава {{ activeStoryChapterNumber }}
                    </a>
                </div>
            </div>
            <div class="mb-3">
                <div class="players-page__field-title mb-3">Начатые книги</div>
                <div class="players-page__list">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Название</th>
                            <th scope="col">Завершено глав</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(storyData, i) of startedStories" :key="i">
                            <td>{{ storyData.id }}</td>
                            <td>
                                <a :href="`/story/${storyData.id}`" target="_blank">{{ storyData.title }}</a>
                            </td>
                            <td>{{ storyData.completedChapters }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mb-3">
                <div class="players-page__field-title mb-3">Социальные сети</div>
                <div class="players-page__list">
                    <table class="table table-striped text-left">
                        <thead>
                        <tr>
                            <th scope="col">Соц. сеть</th>
                            <th scope="col">ID</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="socialName of socialMap" :key="socialName">
                            <td style="width: 20%">{{ socialName }}</td>
                            <td style="user-select: text;">
                                {{
                                    playerData.profile_type === socialName ?
                                        getSocialLink(socialName, playerData.profile_id) :
                                        '-'
                                }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import bus from '@/plugins/bus';
import {mapActions} from 'vuex';
import {openSetUserChapterModal} from '@/modals/setUserChapterModal';

export default {
    data: () => ({
        pageReady: false,
        playerData: null,
        socialMap: ['local', 'facebook', 'google', 'apple'],
        socialOptions: [],
        selectedSocialOption: 0,
        socialId: '',
        progressData: []
    }),
    computed: {
        registrationDate() {
            return this.playerData ? this.playerData.created_at.split('T')[0] : '';
        },
        startedStories() {
            if (!this.pageReady) return [];
            const result = [];
            this.playerData.started_stories.forEach(data => {
                const storyData = this.$store.state.stories.list.find(s => s.id === data.story_id);
                let chapterNumber = 0;

                for (let i = 0; i < storyData.chapters.length; i++)
                    if (storyData.chapters[i].id === data.chapter_id) {
                        chapterNumber = i;
                        break;
                    }

                result.push({
                    id: storyData.id,
                    title: storyData.title,
                    completedChapters: chapterNumber
                });
            });
            return result;
        },
        activeStoryData() {
            if (!this.playerData.last_chapter.id) return false;
            return this.$store.state.stories.list.find(story => story.id === this.playerData.last_chapter.story.id);
        },
        recommendedStoryData() {
            if (this.playerData.last_chapter.id != null || !this.playerData.recommended_story_id) return false;
            const storyData = this.$store.state.stories.list.find(story => story.id === this.playerData.recommended_story_id);
            return storyData || {id: this.playerData.recommended_story_id, title: 'Ошибка: история не найдена'};
        },
        activeStoryChapterNumber() {
            if (!this.activeStoryData) return 0;
            for (let i = 0; i < this.activeStoryData.chapters.length; i++)
                if (this.activeStoryData.chapters[i].id === this.playerData.last_chapter.id)
                    return i + 1;
            return 0;
        }
    },
    components: {
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
    },
    async created() {
        await this.getCurrencies();
        await this.getStories();

        this.socialOptions = this.socialMap.map((text, value) => {
            return {text, value};
        });

        this.pageReady = true;
    },
    methods: {
        ...mapActions('players', ['getPlayer', 'resetPlayer', 'setTesterFlag', 'getUserProgress', 'updateUserCurrency']),
        ...mapActions('currencies', ['getCurrencies']),
        ...mapActions('stories', ['getStories']),
        async findPlayer() {
            if (this.playerData) return;

            if (!this.socialId.length) {
                bus.$emit('show-notification-message', 'Ошибка: Необходимо задать ID для поиска');
                return;
            }

            const res = await this.getPlayer({idType: this.socialMap[this.selectedSocialOption], id: this.socialId});

            if (!res.error)
                this.playerData = res;
            else {
                if (res.error.response?.status) {
                    let errorMessage;
                    switch (res.error.response.status) {
                        case 404:
                            errorMessage = 'Игрок не найден';
                            break;
                        default:
                            errorMessage = `Необработанная ошибка ${res.error.response.status}`;
                            break;
                    }
                    bus.$emit('show-notification-message', `Ошибка: ${errorMessage}`);
                } else {
                    bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
                }
            }

            this.progressData = await this.getUserProgress(this.playerData.id);
        },
        getCurrencyName(currencyID) {
            return this.$store.state.currencies.list.find(currency => currency.id === currencyID).name;
        },
        getStoryName(storyId) {
            return this.$store.getters['stories/getStoryById'](storyId).title;
        },
        getSocialLink(socialName, userId) {
            switch (socialName) {
                case 'facebook':
                    return `https://facebook.com/id${userId}`;
                default:
                    return userId;
            }
        },
        async onResetPlayerClick() {
            if (confirm('Вы уверены, что хотите обнулить игрока?')) {
                const res = await this.resetPlayer(this.playerData.id);

                if (!res.error)
                    this.playerData = res;
                else
                    bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        async onTesterFlagChanged(event) {
            const res = await this.setTesterFlag({playerId: this.playerData.id, isTester: event.target.checked});

            if (!res.error)
                this.playerData = res;
            else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        onSetChapterClick() {
            openSetUserChapterModal(this.playerData.id, this.progressData);
        },
        async setUserCurrency() {
            const res = await this.updateUserCurrency({playerId: this.playerData.id, money: this.playerData.monies});

            if (!res.error) {
                bus.$emit('show-notification-message', 'Обновлено');
                this.playerData.monies = res;
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        }
    }
};
</script>

<style lang="scss">
.players-page {
    &__field-title {
        text-align: left;
        font-weight: 700;
        font-size: 1.2em;
        padding-bottom: 12px;
        border-bottom: 1px dashed rgba(45, 76, 112, 0.5);

        + div {
            font-size: .9em;
        }
    }

    &__find-button {
        max-width: 220px;
    }

    h4 {
        text-align: left;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__list-row {
        display: flex;
        align-items: center;

        div {
            min-width: 100px;
            margin-right: 16px;
            margin-bottom: 12px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 100px 100px;
        grid-gap: 12px;
        text-align: left;

        div {
            font-weight: 700;
        }
    }

    &__search-block {
        display: flex;
        align-items: center;

        * {
            flex: 1 1 100%;
            text-align: left;
        }
    }

    &__label {
        max-width: 100px;
    }

    &__find-value {
        max-width: 240px;
    }
}
</style>
