<template>
    <div class="users-page page">
        <div class="page__title">
            <nav-back/>
            Список пользователей
            <ui-button color="blue" class="page__add-button" @clicked="onAddUserClick">
                Добавить
            </ui-button>
        </div>
        <table class="users-list table table-striped">
            <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Email</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(user, i) of users" :key="i">
                <th scope="row">{{ user.id }}</th>
                <td>{{ user.email }}</td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil page__active-icon" viewBox="0 0 16 16"
                         @click="onEditClick(user)"
                         v-b-tooltip.hover title="Редактировать"
                    >
                        <path
                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash ms-3 page__active-icon" viewBox="0 0 16 16"
                         @click="onDeleteClick(user.id)"
                         v-b-tooltip.hover title="Удалить"
                    >
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-shield-lock page__active-icon" viewBox="0 0 16 16"
                         @click="onChangePasswordClick(user)"
                         v-b-tooltip.hover title="Сменить пароль"
                    >
                        <path
                            d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                        <path
                            d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
                    </svg>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import {openEditUserModal} from '@/modals/editUserModal';
import {openAddUserModal} from '@/modals/addUserModal';
import {openChangeUserPasswordModal} from '@/modals/changeUserPasswordModal';
import bus from '@/plugins/bus';

export default {
    components: {
        UiButton: () => import('@/components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
    },
    created() {
        this.getUsers();
    },
    computed: {
        users() {
            return this.$store.state.users.list;
        }
    },
    methods: {
        ...mapActions('users', ['getUsers', 'deleteUser']),
        onEditClick(user) {
            openEditUserModal(user);
        },
        async onDeleteClick(userId) {
            if (!confirm('Подтвердите удаление')) {
                return;
            }

            const res = await this.deleteUser(userId);

            if (res.error) {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        onAddUserClick() {
            openAddUserModal();
        },
        onChangePasswordClick(user) {
            openChangeUserPasswordModal(user);
        }
    }
};
</script>

<style lang="scss">
.users-list {
    &__icon {
        cursor: pointer;
    }
}
</style>
