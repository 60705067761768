<template>
    <div class="modal-popup change-user-password-modal">
        <div class="mb-3 modal-popup__title">
            Смена пароля

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>

        <input type="email" disabled class="form-control mb-2" placeholder="Email address" :value="user.email"
               ref="input">
        <input type="password" class="form-control mb-2" placeholder="Новый пароль" v-model="password">
        <input type="password" class="form-control mb-2" placeholder="Подтверждение пароля"
               v-model="confirmedPassword" :class="[validationClass()]">

        <ui-button class="mt-3 mb-2" @clicked="onSaveClick" :disabled="!isValidData()">Сохранить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: ['user'],
    data: () => ({
        password: '',
        confirmedPassword: '',
        alert: '',
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('users', ['updateUser']),
        validationClass() {
            if (!this.password) return '';
            return this.password === this.confirmedPassword ? 'password-valid' : 'password-invalid';
        },
        isValidData() {
            if (!this.password) {
                this.setAlert('Необходимо ввести пароль');
                return false;
            }

            if (this.password !== this.confirmedPassword) {
                this.setAlert('Пароли должны совпадать');
                return false;
            }

            this.alert = '';

            return true;
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onSaveClick() {
            if (!this.isValidData()) return;

            let data = {id: this.user.id, password: this.password};

            const res = await this.updateUser(data);

            if (res.error) {
                this.setAlert(`Ошибка: ${res.error.toString()}`);
            } else {
                bus.$emit('show-notification-message', 'Обновлено');
                this.close();
            }
        },
        close() {
            this.$modal.hide('change-user-password-modal');
        }
    }
};
</script>

<style lang="scss">
.change-user-password-modal {
    .password-valid {
        border-color: #008000;
    }

    .password-invalid {
        border-color: #ff0000;
    }
}
</style>
