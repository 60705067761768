import Vue from 'vue';

import {UPDATE, update} from '../utils';
import api from '@/api';
import {findWithAttr} from '@/utils';

const state = {
    list: []
};

const getters = {
    getGenreById: state => id => state.list.find(genre => genre.id === id)
};

const actions = {
    update,
    
    async getGenres({state, commit}) {
        return new Promise(resolve => {
            if (state.list.length) {
                resolve(state.list);
                return;
            }
            
            api.fetch('/genres').then(list => {
                commit('UPDATE', {list});
                resolve(list);
            }).catch(_ => {
                resolve({error: 1});
            });
        });
    },
    
    async addGenre({commit}, name) {
        return new Promise(resolve => {
            api.post('/genres', {name})
                .then(genre => {
                    commit('UPDATE_GENRE', genre);
                    resolve(genre);
                })
                .catch(error => {
                    resolve({error});
                });
        });
    },
    
    async updateGenre({commit}, data) {
        return new Promise(resolve => {
            api.put(`/genres/${data.id}`, data).then(genre => {
                commit('UPDATE_GENRE', genre);
                resolve(genre);
            }).catch(error => {
                resolve({error});
            });
        });
    },
};

const mutations = {
    UPDATE,
    
    UPDATE_GENRE(state, genre) {
        let idx = findWithAttr(state.list, 'id', genre.id);
    
        if (idx > -1) {
            Vue.set(state.list, idx, genre);
        } else {
            Vue.set(state.list, state.list.length, genre);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
