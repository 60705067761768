import Popup from "@/lib/Popup";
import AddStorySkillModal from '@/components/modals/AddStorySkillModal';

export const openAddStorySkillModal = (storyId, tempSkills) => {
    const popup = new Popup({
        component: AddStorySkillModal,
        props: {storyId, tempSkills},
        params: {
            transition: 'scale',
            name: 'add-story-skill-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddStorySkillModal};
