<template>
    <div class="modal-popup add-story-skill-modal" @click="resetAlert" @keyup.13="onAddClick">
        <div class="mb-3 modal-popup__title">
            Добавление характеристики

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <div class="form-group">
            <b-dropdown :text="selectedText" class="m-md-2 add-story-category-modal__filter-dropdown">
                <b-dropdown-item v-for="(skill, i) of availableSkills" :key="i" @click="onSkillSelect"
                                 :data-value="skill.id">
                    {{ skill.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <ui-button class="mt-3 mb-2" @clicked="onAddClick" :disabled="!availableSkills.length">Добавить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: {
        storyId: Number,
        tempSkills: Array
    },
    data: () => ({
        name: '',
        alert: '',
        canReset: false,
        selectedOption: 0
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    async created() {
        await this.getSkills();
    },
    computed: {
        ...mapGetters('stories', ['getStoryById']),
        skills() {
            return this.$store.state.skills.list;
        },
        selectedText() {
            if (!this.selectedOption) {
                if (this.availableSkills.length) {
                    return this.availableSkills[0].name;
                } else {
                    return '';
                }
            }

            const config = this.availableSkills.find(c => c.id === this.selectedOption);
            return config.name || '';
        },
        availableSkills() {
            const story = this.getStoryById(this.storyId);

            if (!story) {
                return this.skills.filter(skill => {
                    const config = this.tempSkills.find(s => s.id === skill.id);
                    return !config;
                });
            }

            if (!story.skills)
                story.skills = [];

            return this.skills.filter(skill => !story.skills.includes(skill.id));
        }
    },
    methods: {
        ...mapActions('skills', ['getSkills']),
        ...mapMutations('stories', ['ADD_STORY_SKILL']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        onSkillSelect(event) {
            this.selectedOption = parseInt(event.currentTarget.dataset.value, 10);
        },
        onAddClick() {
            if (!this.availableSkills.length) {
                return;
            }

            if (this.selectedOption === 0) {
                this.selectedOption = this.availableSkills[0].id;
            }

            this.ADD_STORY_SKILL({
                storyId: this.storyId,
                skillId: this.selectedOption
            });
            bus.$emit('story-skills-changed', this.selectedOption);
            this.close();
        },
        close() {
            this.$modal.hide('add-story-skill-modal');
        }
    }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.add-story-skill-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    min-width: 360px;

    .modal-popup__title {
        width: 100%;
        text-align: center;
    }

    .form-group {
        display: flex;
        justify-self: flex-start;
        align-items: flex-start;
        flex-grow: 1;
        margin-bottom: -4px;

        .dropdown-toggle {
            background: transparent !important;
            color: $primaryColor !important;
            border: 1px solid $primaryColor !important;
            outline: none !important;
            width: auto;
            float: left;
            min-width: 250px;
        }
    }

    .ui-button {
        margin: 0;
    }
}
</style>
