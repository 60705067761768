import Popup from "@/lib/Popup";
import AddSkillModal from '@/components/modals/AddSkillModal';

export const openAddSkillModal = () => {
    const popup = new Popup({
        component: AddSkillModal,
        props: {},
        params: {
            transition: 'scale',
            name: 'add-skills-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddSkillModal};
