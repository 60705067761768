import Popup from "@/lib/Popup";
import AddUserModal from '@/components/modals/AddUserModal';

export const openAddUserModal = () => {
    const popup = new Popup({
        component: AddUserModal,
        props: {},
        params: {
            transition: 'scale',
            name: 'add-user-modal',
            width: '100%',
            height: '100%',
            classes: 'add-user-modal__overlay'
        }
    });
    
    popup.show();
};

export default {openAddUserModal};
