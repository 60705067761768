<template>
    <div class="page fake-stories-page">
        <div class="page__title">
            <nav-back/>
            Фейковые истории
        </div>
        <template v-if="pageIsReady">
            <div class="fake-stories-page__status">
                <label class="my-0">Статус:</label>
                <b-dropdown :text="statusText">
                    <b-dropdown-item v-for="(status, i) of statusOptions" :key="i" @click="onStatusSelect"
                                     :data-value="status.value">
                        {{ status.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="form-group fake-stories-page__input-block">
                <label class="fake-stories-page__label">Кол-во глав:</label>
                <input type="number" v-model.number="chaptersCount"
                       class="form-control fake-stories-page__chapters-value"
                       min=0 oninput="validity.valid||(value=0)"
                >
                <div class="ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Сохранить"
                         @click="onSaveClick" style="cursor: pointer;"
                    >
                        <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path
                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                    </svg>
                </div>

            </div>
        </template>
    </div>
</template>

<script>
import bus from '@/plugins/bus';
import {mapActions} from 'vuex';

export default {
    data: () => ({
        pageIsReady: false,
        chaptersCount: 0,
        statusText: '',
        statusOption: 1,
        statusOptions: [
            {value: 1, name: 'Включено'},
            {value: 0, name: 'Выключено'},
        ]
    }),
    components: {
        UiButton: () => import('../components/ui/UiButton'),
        NavBack: () => import('../components/navigation/NavBack'),
    },
    async mounted() {
        await this.getConfigs();
        this.statusOption = this.$store.state.configs.fake_stories_enabled ? 1 : 0;
        this.chaptersCount = this.$store.state.configs.fake_stories_chapters_count || 0;
        this.statusText = this.statusOptions.find(status => status.value === this.statusOption).name;
        this.pageIsReady = true;
    },
    methods: {
        ...mapActions(['getConfigs', 'saveConfig']),
        async onStatusSelect(event) {
            this.statusOption = parseInt(event.target.dataset.value, 10);

            const res = await this.saveConfig({
                fake_stories_enabled: this.statusOption === 1
            });

            if (!res.error) {
                this.statusText = this.statusOptions.find(status => status.value === this.statusOption).name;
                bus.$emit('show-notification-message', 'Статус обновлен');
            } else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        async onSaveClick() {
            const res = await this.saveConfig({
                fake_stories_chapters_count: this.chaptersCount
            });

            if (!res.error)
                bus.$emit('show-notification-message', 'Обновлено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        }
    }
};
</script>

<style lang="scss">
.fake-stories-page {
    &__status {
        display: grid;
        grid-template-columns: 100px 100px;
        align-items: center;
        margin-bottom: 8px;
        text-align: left;
    }

    &__input-block {
        display: flex;
        align-items: baseline;

        * {
            flex: 1 1 100%;
            text-align: left;
        }
    }

    &__label {
        max-width: 100px;
    }

    &__chapters-value {
        max-width: 240px;
    }
}
</style>
