<template>
    <div class="login-page page" v-on:keyup.13="onLoginClick">
        <div class="login-page__content">
            <div class="login-page__logo">NOVEL ADMIN</div>
            <div class="login-page__title">Авторизация</div>
            <div class="login-page__inputs">
                <span class="login-page__text">E-mail</span>
                <input type="email" class="form-control" placeholder="Email address" v-model="email"
                       :class="[validationClass()]" ref="input">

                <span class="login-page__text">Пароль</span>
                <input type="password" class="form-control" placeholder="Пароль" v-model="password">
            </div>

            <ui-button color="blue" class="mt-3" @clicked="onLoginClick">Войти</ui-button>
        </div>
    </div>

</template>

<script>
import {mapActions} from 'vuex';
import {validateEmail} from '@/utils';
import bus from '@/plugins/bus';

export default {
    name: 'login',
    data: () => ({
        email: '',
        password: '',
    }),
    components: {
        UiButton: () => import('../components/ui/UiButton')
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('currentUser', ['auth']),
        validationClass() {
            return (this.email === '') ? '' : (validateEmail(this.email)) ? 'email-valid' : 'email-invalid';
        },
        async onLoginClick() {
            if (!validateEmail(this.email)) {
                bus.$emit('show-notification-message', 'Введен некорректный email');
                return;
            }

            if (this.password === '') {
                bus.$emit('show-notification-message', 'Необходимо ввести пароль');
                return;
            }

            const res = await this.auth({email: this.email, password: this.password});

            if (res.error) {
                this.email = '';
                this.password = '';
            } else {
                await this.$router.push('/stories');
            }
        }
    }
};
</script>

<style lang="scss">
@import "../assets/scss/mixins";
@import "../assets/scss/variables";

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 20px;

    &__inputs {
        margin-top: 32px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #FFF;
        box-shadow: 0 4px 16px rgba(45, 76, 112, .24);
        border-radius: 24px;
        padding: 44px 100px;
    }

    &__logo {
        color: #2D4C70;
        font-size: 32px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        text-align: center;
    }

    .form-control {
        margin: 0 auto 24px;
        max-width: 330px;

        .email-valid {
            border-color: #008000;
        }

        .email-invalid {
            border-color: #ff0000;
        }
    }

    &__text {
        float: left;
        @include font-open-sans(16, bold);
        color: $primaryColor;
        margin-bottom: 16px;
        margin-left: 2px;
    }

    .ui-button {
        width: 184px;
        text-transform: uppercase;
    }
}
</style>
