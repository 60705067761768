import Popup from "@/lib/Popup";
import EditCategoryModal from '@/components/modals/EditCategoryModal';

export const openEditCategoryModal = category => {
    const popup = new Popup({
        component: EditCategoryModal,
        props: {category},
        params: {
            transition: 'scale',
            name: 'edit-category-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openEditCategoryModal};
