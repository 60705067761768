import Popup from "@/lib/Popup";
import EditGenreModal from '@/components/modals/EditGenreModal';

export const openEditGenreModal = genre => {
    const popup = new Popup({
        component: EditGenreModal,
        props: {genre},
        params: {
            transition: 'scale',
            name: 'edit-genre-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openEditGenreModal};
