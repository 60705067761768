<template>
    <div class="daily-bonus-item">
        <div class="daily-bonus-item__header"
             :class="[`daily-bonus-item__header--${collapsedClass}`]"
             @click="collapsed = !collapsed"
        >
            <div class="float-left">День #{{ id }}</div>
            <i class="daily-bonus-item__collapse-button"/>
            <ui-button color="transparent" size="small" class="daily-bonus-item__delete-button"
                       @clicked="onDeleteClick">
                Удалить
            </ui-button>
        </div>
        <div v-if="!collapsed" class="daily-bonus-item__settings">
            <label class="my-0 text-left">Валюта</label>
            <b-dropdown :text="currencyText">
                <b-dropdown-item v-for="(currency, i) of currenciesOptions" :key="i" @click="onCurrencyOptionSelect"
                                 :data-value="currency.id">
                    {{ currency.name }}
                </b-dropdown-item>
            </b-dropdown>

            <label class="my-0 text-left">Количество</label>
            <input type="number" class="form-control" v-model.number="value"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">VIP бонус</label>
            <input type="number" class="form-control" v-model.number="vip_value"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">Рекламный бонус</label>
            <input type="number" class="form-control" v-model.number="ads_value"
                   min=0 oninput="validity.valid||(value=0)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialData: Object,
        currencies: Array
    },
    data: () => ({
        collapsed: true,
        currencyText: '',
        currenciesOptions: [],

        id: Number,
        currency_id: Number,
        value: Number,
        vip_value: Number,
        ads_value: Number
    }),
    components: {
        UiButton: () => import('../ui/UiButton'),
    },
    computed: {
        collapsedClass() {
            return this.collapsed ? 'collapsed' : 'expanded';
        }
    },
    created() {
        this.currenciesOptions = this.currencies.filter(currency => currency.id !== 0);
        this.setData(this.initialData);
    },
    watch: {
        value(value, oldValue) {
            if (isNaN(oldValue)) return;
            this.$emit('changed', this.id, 'value', value);
        },
        vip_value(value, oldValue) {
            if (isNaN(oldValue)) return;
            this.$emit('changed', this.id, 'vip_value', value);
        },
        ads_value(value, oldValue) {
            if (isNaN(oldValue)) return;
            this.$emit('changed', this.id, 'ads_value', value);
        },
        currency_id(value, oldValue) {
            if (isNaN(oldValue)) return;
            this.$emit('changed', this.id, 'currency_id', value);
        }
    },
    methods: {
        onCurrencyOptionSelect(event) {
            this.currency_id = parseInt(event.target.dataset.value, 10);
            this.currencyText = this.currenciesOptions.find(currency => currency.id === this.currency_id).name;
        },
        setData(data) {
            this.id = data.id;
            this.value = data.value;
            this.vip_value = data.vip_value;
            this.ads_value = data.ads_value;
            this.currency_id = data.currency_id;
            this.currencyText = this.currenciesOptions.find(currency => currency.id === this.currency_id).name;
        },
        getData() {
            return {
                id: parseInt(this.id, 10),
                value: parseInt(this.value, 10),
                vip_value: parseInt(this.vip_value, 10),
                ads_value: parseInt(this.ads_value, 10),
                currency_id: parseInt(this.currency_id, 10)
            };
        },
        onDeleteClick() {
            this.$emit('deleted', this.id);
        }
    }
};
</script>

<style lang="scss">
.daily-bonus-item {
    &__header {
        position: relative;
        width: 100%;
        height: 22px;

        .daily-bonus-item__collapse-button {
            float: left;
            margin-left: 4px;

            &:after {
                content: '';
                display: block;
                float: left;
            }
        }

        &--collapsed {
            .daily-bonus-item__collapse-button:after {
                content: '▼';
            }
        }

        &--expanded {
            padding-bottom: 36px;
            margin-bottom: 12px;
            border-bottom: 1px solid rgba(154, 170, 188, .5);

            .daily-bonus-item__collapse-button:after {
                content: '▲';
            }
        }
    }

    &__settings {
        margin-top: 12px;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-row-gap: 12px;
        grid-column-gap: 8px;
        align-items: center;
    }

    &__delete-button {
        float: right;
    }
}
</style>
