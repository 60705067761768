import Popup from "@/lib/Popup";
import AddStoryGenreModal from '@/components/modals/AddStoryGenreModal';

export const openAddStoryGenreModal = (storyId, tempGenres) => {
    const popup = new Popup({
        component: AddStoryGenreModal,
        props: {storyId, tempGenres},
        params: {
            transition: 'scale',
            name: 'add-story-genre-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddStoryGenreModal};
