<template>
    <div class="page economy-page">
        <div class="page__title">
            <nav-back/>
            Экономика - общие значения
        </div>

        <div class="form-group">
            <div class="form-group__left-block">
                <label class="economy-page__label">Награда за прохождение главы</label>
                <b-form-select v-model="selectedChapterRewardOption" :options="currenciesOptions" class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="chapterRewardValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveChapterRewardClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Награда за прохождение истории</label>
                <b-form-select v-model="selectedStoryRewardOption" :options="currenciesOptions" class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="storyRewardValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveStoryRewardClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Цена кастомизации</label>
                <b-form-select v-model="selectedCustomizationOption" :options="currenciesOptions" class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="customizationPriceValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveCustomizationPriceClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Цена открытия истории</label>
                <b-form-select v-model="selectedUnlockStoryOption" :options="currenciesOptions" class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="unlockStoryPriceValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveUnlockStoryPriceClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        currenciesOptions: [
            {
                value: 0,
                text: 'Ожидание'
            }
        ],

        selectedChapterRewardOption: 0,
        selectedStoryRewardOption: 0,
        selectedCustomizationOption: 0,
        selectedUnlockStoryOption: 0,

        chapterRewardValue: 0,
        storyRewardValue: 0,
        customizationPriceValue: 0,
        unlockStoryPriceValue: 0,
    }),
    components: {
        NavBack: () => import('../components/navigation/NavBack')
    },
    computed: {
        ...mapState(['configs']),
        currencies() {
            return this.$store.state.currencies.list;
        }
    },
    async created() {
        await this.getConfigs();
        await this.getCurrencies();
        this.fillSelects();
    },
    methods: {
        ...mapActions(['getConfigs', 'saveConfig']),
        ...mapActions('currencies', ['getCurrencies']),
        fillSelects() {
            this.currenciesOptions = this.currencies
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {
                        value: currency.id,
                        text: currency.name
                    };
                });

            this.selectedChapterRewardOption = this.configs.chapter_reward_currency_id || 1;
            this.chapterRewardValue = this.configs.chapter_reward_currency_value || 0;

            this.selectedStoryRewardOption = this.configs.story_reward_currency_id || 1;
            this.storyRewardValue = this.configs.story_reward_currency_value || 0;

            this.selectedCustomizationOption = this.configs.customization_currency_id || 1;
            this.customizationPriceValue = this.configs.customization_currency_value || 0;

            this.selectedUnlockStoryOption = this.configs.story_unlock_currency_id || 1;
            this.unlockStoryPriceValue = this.configs.story_unlock_currency_value || 0;
        },
        checkResponse(res) {
            if (!res.error) {
                bus.$emit('show-notification-message', 'Сохранено');
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
            }
        },
        async onSaveChapterRewardClick() {
            let data = {
                chapter_reward_currency_id: parseInt(this.selectedChapterRewardOption, 10),
                chapter_reward_currency_value: parseInt(this.chapterRewardValue, 10)
            };

            const res = await this.saveConfig(data);

            this.checkResponse(res);
        },
        async onSaveStoryRewardClick() {
            let data = {
                story_reward_currency_id: parseInt(this.selectedStoryRewardOption, 10),
                story_reward_currency_value: parseInt(this.storyRewardValue, 10)
            };

            const res = await this.saveConfig(data);

            this.checkResponse(res);
        },
        async onSaveCustomizationPriceClick() {
            let data = {
                customization_currency_id: parseInt(this.selectedCustomizationOption, 10),
                customization_currency_value: parseInt(this.customizationPriceValue, 10)
            };

            const res = await this.saveConfig(data);

            this.checkResponse(res);
        },
        async onSaveUnlockStoryPriceClick() {
            let data = {
                story_unlock_currency_id: parseInt(this.selectedUnlockStoryOption, 10),
                story_unlock_currency_value: parseInt(this.unlockStoryPriceValue, 10)
            };

            const res = await this.saveConfig(data);

            this.checkResponse(res);
        }
    }
};
</script>

<style lang="scss">
.economy-page {
    &__label {
        float: left;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__left-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 25%;
        }

        &__right-block {
            cursor: pointer;
            margin: 32px 0 0 16px;
        }
    }

    .form-control {
        display: flex;
    }
}
</style>
